import ArrowRightLongIcon from '../svg/ArrowRightLongIcon';

export default function SlidingSelect({Icon, text, url}) {
    return (
        <a className='sliding-select' href={url}>
            <div className='sliding-select__icon-box'>
                <Icon className='sliding-select__icon' />
            </div>
            <div className='sliding-select__label-box'>
                <label className='sliding-select__label'>{text}</label>
            </div>
            <div className='sliding-select__arrow-box'>
                <ArrowRightLongIcon className='sliding-select__arrow' />
            </div>
        </a>
    );
}