import {useState, useEffect} from 'react';
import axios from 'axios';


export default function UrgentNotification() {
    const [urgentNotificationContent, setUrgentNotificationContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/misc`, {params: {id: 'top_notification'}})
        .then(({data}) => {
            setUrgentNotificationContent(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch Top Notification content [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    return (
        <div className='urgent-notification' id='js-urgent-notification'>
            <label className='urgent-notification__label' dangerouslySetInnerHTML={{__html: urgentNotificationContent}}></label>
        </div>
    );
}