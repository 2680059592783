// React:
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import InfoBox from '../components/InfoBox';
import MediumImage from '../components/MediumImage';
import SlidingButton from '../components/SlidingButton';
import PriceTable from '../components/PriceTable';
import Picture from '../img/pcs.jpg';

export default function DentalSurgeryClinicPage() {
    const [infoContent, setInfoContent] = useState('');
    const [services, setServices] = useState([]);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/clinic`, {params: {id: 'dental_surgery_clinic'}})
        .then(({data}) => {
            setInfoContent(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch clinic content [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/service`, {params: {clinic: 'pcs_nfz'}})
        .then(({data}) => {
            setServices(data);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch services [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/misc`, {params: {id: 'pcs_nfz_notes'}})
        .then(({data}) => {
            setNotes(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch notes [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    const clinicName = 'Poradnia Chirurgii Stomatologicznej';
    const clinicCaption = `Kierownik: dr n. med. Maciej Wrzoł`;
    const infoTitle = 'Informacje ogólne';

    return (
        <article className='clinic-page'>
            <Helmet>
                <title>Poradnia Chirurgii Stomatologicznej | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <label className='clinic-page__title'>{clinicName}</label>
            <label className='clinic-page__caption' dangerouslySetInnerHTML={{__html:clinicCaption}}></label>

            <div className='clinic-page__section'>
                <MediumImage src={Picture} alt='Poradnia chirurgii stomatologicznej' />
                <InfoBox title={infoTitle} content={infoContent} additionalStyle={{top: '-70px'}} />
            </div>
            
            <div className='clinic-page__section'>
                <label className='clinic-page__subtitle'>Bezpłatne świadczenia NFZ</label>
                <label className='clinic-page__subtitle-caption'>Bezpłatne świadczenia w ramach kontraktu z zakresu chirurgii stomatologicznej i periodontologii gwarantowane przez Narodowy Fundusz Zdrowia m.in. takie jak:</label>
                <PriceTable services={services} />
                <label className='clinic-page__info clinic-page__info--small'>{notes}</label>
            </div>
            
            <div className='clinic-page__section'>
                <label className='clinic-page__subtitle'>Cennik świadczeń komercyjnych</label>
                <SlidingButton text='Przejdź do cennika' url='/cennik/poradnia-chirurgii-stomatologicznej' />
            </div>
        </article>
    );
}