export default function QuoteIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M1695 4809 c-72 -17 -397 -186 -535 -278 -179 -119 -280 -200 -431
                -351 -489 -486 -674 -997 -719 -1985 -7 -142 -10 -539 -8 -975 3 -683 4 -738
                21 -771 24 -47 71 -95 121 -122 l41 -22 865 0 c707 0 872 3 904 14 49 17 111
                73 139 125 l22 41 3 854 c3 939 4 918 -58 993 -16 20 -53 48 -82 62 l-52 26
                -413 0 -413 0 0 73 c0 160 39 385 95 553 127 380 348 620 765 829 81 41 160
                87 178 106 46 47 65 105 60 181 -4 56 -18 92 -123 311 -123 257 -146 292 -219
                322 -45 19 -115 25 -161 14z"/>
                <path d="M4540 4777 c-673 -294 -1186 -794 -1413 -1377 -65 -166 -123 -419
                -156 -680 -42 -327 -46 -450 -46 -1360 0 -839 1 -877 19 -913 24 -49 67 -92
                120 -120 l41 -22 870 0 c863 0 870 0 915 21 54 25 94 64 124 119 21 40 21 42
                24 894 3 939 4 918 -58 993 -16 20 -53 48 -82 62 l-52 26 -418 0 c-407 0 -418
                0 -418 19 0 75 26 296 46 396 31 154 64 253 129 390 138 290 348 485 705 657
                184 88 224 135 225 263 0 69 -2 73 -115 308 -63 130 -128 254 -144 274 -71 86
                -190 105 -316 50z"/>
            </g>
        </svg>
    );
}