// React:
import {useState, useEffect} from 'react';
import axios from 'axios';

// Icons:
import DeleteIcon from '../svg/DeleteIcon';


function ClientMessage({messages, setMessages, setNotification, id, index, name, phone, email, message, date}) {
    function deleteMessage() {
        // In DELETE requests data has to be in options parameter:
        const options = {
            withCredentials: true,
            data: {
                id: id
            }
        };

        axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/api/message`, options)
        .then(({data}) => {
            const newMessages = messages.filter(item => item._id !== id);
            setMessages(newMessages);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch client messages [${error.response.status}]`);
                if (error.response.status === 401) {
                    setNotification({code: -1, msg: 'Nie jesteś zalogowany lub twoja sesja wygasła. Zaloguj się ponownie.'});
                }

                else {
                    setNotification({code: -1, msg: `Wystąpił błąd [${error.response.status}]`});
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    return (
        <div className='admin-client-message__wrapper'>
            <div className='admin-client-message__side'>
                <label className='admin-client-message__big-number'>{index.toString().padStart(2, '0') + ''}</label>
                <div className='admin-client-message__delete' onClick={deleteMessage}>
                    <DeleteIcon className='admin-client-message__icon' />
                </div>
            </div>
            <div className='admin-client-message'>
                <div className='admin-client-message__info-wrapper'>
                    <label className='admin-client-message__info-title'>Imię i nazwisko:</label>
                    <label className='admin-client-message__info'>{name}</label>
                </div>
                <div className='admin-client-message__info-wrapper'>
                    <label className='admin-client-message__info-title'>Telefon:</label>
                    <a className='admin-client-message__info' href={phone === '---' ? undefined : `tel:${phone}`}>{phone}</a>
                </div>
                <div className='admin-client-message__info-wrapper'>
                    <label className='admin-client-message__info-title'>Email:</label>
                    <a className='admin-client-message__info' href={`mailto:${email}`}>{email}</a>
                </div>
                <div className='admin-client-message__info-wrapper'>
                    <label className='admin-client-message__info-title'>Wiadomość:</label>
                    <label className='admin-client-message__info'>{message}</label>
                </div>
                <div className='admin-client-message__info-wrapper admin-client-message__info-wrapper--bottom'>
                    <label className='admin-client-message__info-title'>Data wysłania:</label>
                    <label className='admin-client-message__info'>{date}</label>
                </div>
            </div>
        </div>
    );
}


export default function AdminClientMessages() {
    const [notification, setNotification] = useState({code: 0, msg: ''});
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        setNotification({code: 0, msg: 'Trwa pobieranie wiadomości...'});

        const options = {
            withCredentials: true
        };

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/message`, options)
        .then(({data}) => {
            setMessages(data);

            if (data.length) {
                setNotification({code: 0, msg: ''});
            }

            else {
                setNotification({code: 0, msg: 'Brak nowych wiadomości.'});
            }
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch client messages [${error.response.status}]`);
                if (error.response.status === 401) {
                    setNotification({code: -1, msg: 'Nie jesteś zalogowany lub twoja sesja wygasła. Zaloguj się ponownie.'});
                }

                else {
                    setNotification({code: -1, msg: `Wystąpił błąd [${error.response.status}]`});
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    var elements = [];
    messages.forEach((item, i) => {
        const date = new Date(item.createdAt);
        const formattedDate = `${date.toLocaleDateString('pl')} | ${date.toLocaleTimeString('pl')}`;
        elements.push(
            <ClientMessage key={item._id} messages={messages} setMessages={setMessages} setNotification={setNotification} id={item._id} index={i+1} name={item.name} phone={item.phone} email={item.email} message={item.message} date={formattedDate} />
        );
    });

    return (
        <div className='admin-client-message__main'>
            <label className={'admin-client-message__notification' + (notification.code === -1 ? ' admin-client-message__notification--error' : '')} style={{display: (notification.msg ? 'initial' : 'none')}}>{notification.msg}</label>
            {elements}
        </div>
    );
}