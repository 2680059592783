import logo from '../img/logo.png';
import FacebookIcon from '../svg/FacebookIcon';
import InstagramIcon from '../svg/InstagramIcon';
import PinIcon from '../svg/PinIcon';
import PhoneIcon from '../svg/PhoneIcon';
import EmailIcon from '../svg/EmailIcon';

export default function Footer({setCookieBannerVisible}) {
    return (
        <footer className='footer'>
            <section className='footer__section'>
                <figure className='footer__logo-container'>
                    <img className='footer__logo' src={logo} alt='Logo' />
                    <figcaption className='footer__logo-caption'>Specjalistyczne Centrum Medyczne</figcaption>
                </figure>
                <a className='footer__navigation-link footer__navigation-link--bold' href='/admin/login'>Panel Administratora</a>
                <div className='footer__icon-container'>
                    <a href='https://www.facebook.com/specjalistyczna' target='_blank' rel='noreferrer'><FacebookIcon className='footer__icon' /></a>
                    <a href='https://www.instagram.com/centrum_medyczne_rybnik/' target='_blank' rel='noreferrer'><InstagramIcon className='footer__icon' /></a>
                </div>
                <small className='footer__copyright'>Copyright © {new Date().getFullYear()}</small>
                <label className='footer__author'>Stronę zaprojektował i wykonał <a className='footer__author-link' href='https://github.com/kubaplo'>Jakub Płocidem</a></label>
            </section>
            
            <section className='footer__section footer__section--align-left footer__section--bigger-gap'>
                <div className='footer__detail-box'>
                    <PinIcon className='footer__detail-icon footer__detail-icon--bigger' />
                    <div className='footer__detail-label-group'>
                        <label className='footer__detail-label footer__detail-label--title'>Lokalizacja:</label>
                        <label className='footer__detail-label'>ul. Patriotów 11 (parter)<br/>44-253 Rybnik<br/>Polska</label>
                    </div>
                </div>
                <div className='footer__detail-box'>
                    <PhoneIcon className='footer__detail-icon' />
                    <div className='footer__detail-label-group'>
                        <label className='footer__detail-label footer__detail-label--title'>Telefon:</label>
                        <a href='tel:324408877' className='footer__detail-link'>32 440 88 77</a>
                    </div>
                </div>
                <div className='footer__detail-box'>
                    <EmailIcon className='footer__detail-icon' />
                    <div className='footer__detail-label-group'>
                        <label className='footer__detail-label footer__detail-label--title'>Email:</label>
                        <a href='mailto:rejestracja@specjalistyczna.com.pl' className='footer__detail-link'>rejestracja@specjalistyczna.com.pl</a>
                    </div>
                </div>
            </section>

            <section className='footer__section footer__section--row'>
                <div className='footer__links-container'>
                    <label className='footer__links-title'>Poradnie</label>
                    <a href='/poradnie/poradnia-chirurgii-stomatologicznej' className='footer__navigation-link'>Chirurgia Stomatologiczna</a>
                    <a href='/poradnie/poradnia-stomatologiczna' className='footer__navigation-link'>Stomatologia</a>
                    <a href='/poradnie/poradnia-otorynolaryngologiczna' className='footer__navigation-link'>Otorynolaryngologia</a>
                    <a href='/poradnie/pracownia-rentgenowska' className='footer__navigation-link'>Pracownia Rentgenowska</a>
                </div>
                <div className='footer__links-container'>
                    <label className='footer__links-title'>Implantologia</label>
                    <a href='/implantologia' className='footer__navigation-link'>Implanty zębowe</a>
                </div>
                <div className='footer__links-container'>
                    <label className='footer__links-title'>Dokumenty</label>
                    <a href='/dokumenty/polityka-plików-cookie' className='footer__navigation-link'>Polityka plików cookie</a>
                    <a href='/dokumenty/polityka-prywatności' className='footer__navigation-link'>Polityka prywatności</a>
                    <a href='/dokumenty/rtg-wpływ-na-ludzi-i-środowisko' className='footer__navigation-link'>RTG - wpływ na ludzi i środowisko</a>
                    <label onClick={() => setCookieBannerVisible(true)} className='footer__navigation-link'>Zmień ustawienia cookie</label>
                </div>
            </section>
        </footer>
    );
}