// React:
import {useState} from 'react';
import axios from 'axios';

// Components:
import FormFieldRegular from './FormFieldRegular';
import RegularButton from './RegularButton';


export default function AdminChangePassword() {
    const [notification, setNotification] = useState({code: 1, msg: ''});

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [newPasswordError2, setNewPasswordError2] = useState('');

    function handlePasswordChange() {
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            newPassword2: newPassword2
        };

        const options = {
            withCredentials: true
        };

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/change-password`, data, options)
        .then(({data}) => {
            setOldPasswordError('');
            setNewPasswordError('');
            setNewPasswordError2('');
            setOldPassword('');
            setNewPassword('');
            setNewPassword2('');
            setNotification({code: 1, msg: 'Hasło zostało pomyślnie zmienione!'});
            setTimeout(() => setNotification({code: 1, msg: ''}), 5000);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                if (error.response.status === 422) {
                    setOldPasswordError(error.response.data.oldPassword?.msg);
                    setNewPasswordError(error.response.data.newPassword?.msg);
                    setNewPasswordError2(error.response.data.newPassword2?.msg);
                }

                else if (error.response.status === 401) {
                    setNotification({code: -1, msg: 'Nie jesteś zalogowany lub twoja sesja wygasła. Zaloguj się ponownie.'})
                }

                else {
                    console.error(`API: Unable to change password [${error.response.status}]`);
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    return (
        <div className='admin-change-password'>
            <label className='admin-change-password__title'>Zmień hasło</label>
            <div className='admin-change-password__fields'>
                <FormFieldRegular value={oldPassword} handleChange={(e) => setOldPassword(e.target.value)} placeholder='Podaj obecne hasło' error={oldPasswordError} type='password' />
                <FormFieldRegular value={newPassword} handleChange={(e) => setNewPassword(e.target.value)} placeholder='Podaj nowe hasło' error={newPasswordError} type='password' />
                <FormFieldRegular value={newPassword2} handleChange={(e) => setNewPassword2(e.target.value)} placeholder='Powtórz nowe hasło' error={newPasswordError2} type='password' />
            </div>
            <RegularButton text='Zmień hasło' onClick={handlePasswordChange} />
            <label className={'admin-change-password__notification' + (notification.code === 1 ? '' : ' admin-change-password__notification--error')}>{notification.msg}</label>
        </div>
    );
}