import DoctorMaleIcon from '../svg/DoctorMaleIcon';
import DoctorFemaleIcon from '../svg/DoctorFemaleIcon';

export default function DoctorInfo({name, major, content, type}) {
    var icon = <img alt='Doctor Icon' />;
    if (type === 'male') {
        icon = <DoctorMaleIcon className='doctor-info__icon' />;
    }
    
    else if (type === 'female') {
        icon = <DoctorFemaleIcon className='doctor-info__icon' />;
    }

    return (
        <section className='doctor-info'>
            <div className='doctor-info__title-box'>
                <div className='doctor-info__title-info-box'>
                    <label className='doctor-info__name'>{name}</label>
                    <label className='doctor-info__major'>{major}</label>
                </div>
                {icon}
            </div>
            <div className='doctor-info__content-box'>
                <p className='doctor-info__content'>
                    {content}
                </p>
            </div>
        </section>
    );
}