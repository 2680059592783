import ArrowRight from '../svg/ArrowRightIcon';

export default function SlidingButton({text, url, newPage=false}) {
    var attrs = {};
    if (newPage) {
        attrs.target = '_blank';
        attrs.rel = 'noreferrer';
    }

    return (
        <a className='sliding-button' href={url} {...attrs}>
            <div className='sliding-button__icon-container'>
                <ArrowRight className='sliding-button__arrow' />
            </div>
            <label className='sliding-button__label'>{text}</label>
            <div className='sliding-button__background'></div>
        </a>
    );
}