import {Helmet} from 'react-helmet';


export default function Document({title, content, buttons}) {
    return (
        <>
        <Helmet>
            <title>{title + ' | Specjalistyczne Centrum Medyczne'}</title>
        </Helmet>

        <div className='document'>
            <label className='document__title'>{title}</label>
            <p className='document__content' dangerouslySetInnerHTML={{__html: content}}></p>
            <div className='document__buttons-layout'>
                {buttons}
            </div>
        </div>
        </>
    );
}