import {useEffect} from 'react';
import logo from '../img/logo.png';
import ArrowDown from '../svg/ArrowDownIcon';

export default function NavbarMobile() {
    useEffect(() => {        
        const navbar = document.getElementById('js-navbar-mobile');
        const urgentNotification = document.getElementById('js-urgent-notification');
        var fixed = false;

        const onScroll = () => {
            if (window.innerWidth <= 1280 && !fixed && window.scrollY >= urgentNotification.offsetHeight) {
                navbar.setAttribute('class', 'navbar-mobile navbar-mobile--fixed');
                urgentNotification.setAttribute('class', 'urgent-notification urgent-notification--extended-margin');
                fixed = true;
            }

            else if (window.innerWidth <= 1280 && fixed && window.scrollY < urgentNotification.offsetHeight) {
                navbar.setAttribute('class', 'navbar-mobile');
                urgentNotification.setAttribute('class', 'urgent-notification');
                fixed = false;
            }
        }

        onScroll();

        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    function handleHamburgerClick() {
        const bar = document.getElementById('js-hamburger-bar');
        const menu = document.getElementById('js-hamburger-menu');

        if (bar.getAttribute('class').search('navbar-mobile__hamburger-bar--active') < 0) {
            bar.setAttribute('class', 'navbar-mobile__hamburger-bar navbar-mobile__hamburger-bar--active');
            menu.setAttribute('class', 'navbar-mobile__menu');
        }

        else {
            bar.setAttribute('class', 'navbar-mobile__hamburger-bar');
            menu.setAttribute('class', 'navbar-mobile__menu navbar-mobile__menu--hidden');
        }
    }

    function handleDropdownClick(event) {
        const dropdown = document.getElementById('js-mobile-dropdown');
        const arrows = Array.from(document.getElementsByClassName('navbar-mobile__arrow-down'));
        const links = Array.from(document.getElementsByClassName('navbar-mobile__menu-link'));
        const nextLinkIndex = links.indexOf(event.target) + 1;
        const nextLink = links[nextLinkIndex];
        
        if (dropdown.getAttribute('class').search('navbar-mobile__dropdown--hidden') < 0) {
            dropdown.setAttribute('class', 'navbar-mobile__dropdown navbar-mobile__dropdown--hidden');
            arrows.forEach((item, i) => {
                item.setAttribute('class', 'navbar-mobile__arrow-down');
            });
            nextLink.setAttribute('class', 'navbar-mobile__menu-link');
        }

        else {
            dropdown.setAttribute('class', 'navbar-mobile__dropdown');
            arrows.forEach((item, i) => {
                if (i % 2 === 0) {
                    item.setAttribute('class', 'navbar-mobile__arrow-down navbar-mobile__arrow-down--up-right');
                }
                else {
                    item.setAttribute('class', 'navbar-mobile__arrow-down navbar-mobile__arrow-down--up-left');
                }
            });
            nextLink.setAttribute('class', 'navbar-mobile__menu-link navbar-mobile__menu-link--border-top');
        }
    }

    return (
        <nav className='navbar-mobile' id='js-navbar-mobile'>
            <a className='navbar-mobile__logo-group' href='/'>
                <img className='navbar-mobile__logo' src={logo} alt='Logo' />
                <label className='navbar-mobile__title'>Specjalistyczne Centrum Medyczne</label>
            </a>
            <div className='navbar-mobile__hamburger' onClick={handleHamburgerClick}>
                <div className='navbar-mobile__hamburger-bar' id='js-hamburger-bar'></div>
            </div>
            <div className='navbar-mobile__menu navbar-mobile__menu--hidden' id='js-hamburger-menu'>
                <div className='navbar-mobile__menu-link' onClick={(event) => handleDropdownClick(event)}>
                    <ArrowDown className='navbar-mobile__arrow-down' />
                    <label className='navbar-mobile__menu-link-label'>Poradnie</label>
                    <ArrowDown className='navbar-mobile__arrow-down' />
                </div>

                <div className='navbar-mobile__dropdown navbar-mobile__dropdown--hidden' id='js-mobile-dropdown'>
                    <a className='navbar-mobile__dropdown-link' href='/poradnie/poradnia-chirurgii-stomatologicznej'>
                        <label className='navbar-mobile__dropdown-link-label'>Poradnia Chirurgii Stomatologicznej</label>
                    </a>
                    <a className='navbar-mobile__dropdown-link' href='/poradnie/poradnia-stomatologiczna'>
                        <label className='navbar-mobile__dropdown-link-label'>Poradnia Stomatologiczna</label>
                    </a>
                    <a className='navbar-mobile__dropdown-link' href='/poradnie/poradnia-otorynolaryngologiczna'>
                        <label className='navbar-mobile__dropdown-link-label'>Poradnia Otorynolaryngologiczna</label>
                    </a>
                    <a className='navbar-mobile__dropdown-link' href='/poradnie/pracownia-rentgenowska'>
                        <label className='navbar-mobile__dropdown-link-label'>Pracownia Rentgenowska</label>
                    </a>
                </div>

                <a className='navbar-mobile__menu-link' href='/implantologia'>
                    <label className='navbar-mobile__menu-link-label'>Implantologia</label>
                </a>
                <a className='navbar-mobile__menu-link' href='/cennik/poradnia-chirurgii-stomatologicznej'>
                    <label className='navbar-mobile__menu-link-label'>Cennik</label>
                </a>
                <a className='navbar-mobile__menu-link' href='/#kontakt' onClick={handleHamburgerClick}>
                    <label className='navbar-mobile__menu-link-label'>Kontakt</label>
                </a>
                <a className='navbar-mobile__menu-link' href='/#zespół' onClick={handleHamburgerClick}>
                    <label className='navbar-mobile__menu-link-label'>Zespół</label>
                </a>
            </div>
        </nav>
    );
}