export default function RadiationIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2315 5109 c-768 -73 -1471 -499 -1901 -1154 -467 -712 -544 -1640
                -199 -2420 136 -307 308 -556 546 -792 174 -172 204 -188 254 -138 47 46 37
                65 -111 208 -208 201 -353 391 -477 627 -84 158 -196 448 -179 464 3 4 79 6
                169 6 160 0 164 0 173 -22 101 -250 170 -381 288 -548 87 -124 337 -375 459
                -460 287 -202 581 -322 918 -376 152 -25 458 -25 610 0 632 101 1161 459 1486
                1004 54 90 87 161 179 379 9 23 13 23 174 23 91 0 167 -3 170 -8 6 -10 -61
                -204 -108 -314 -182 -419 -518 -809 -917 -1063 -197 -126 -454 -238 -674 -295
                -616 -158 -1248 -77 -1797 230 -59 33 -115 60 -126 60 -34 0 -72 -38 -72 -72
                0 -47 45 -80 244 -178 185 -91 343 -150 521 -194 774 -193 1597 -11 2220 491
                127 102 338 320 433 446 433 574 604 1285 482 1991 -65 371 -217 733 -439
                1044 -67 95 -88 110 -136 98 -32 -8 -59 -49 -51 -79 3 -13 31 -59 63 -102 77
                -106 192 -305 244 -423 50 -114 119 -313 113 -324 -3 -5 -79 -8 -170 -8 -161
                0 -165 0 -174 23 -125 296 -183 405 -308 574 -88 118 -325 353 -439 433 -528
                372 -1139 487 -1753 330 -265 -68 -555 -216 -781 -398 -109 -88 -293 -283
                -371 -393 -117 -164 -186 -296 -288 -546 -9 -23 -13 -23 -180 -23 -93 0 -170
                2 -170 4 0 3 11 41 25 85 213 673 756 1250 1417 1505 866 334 1821 155 2511
                -472 49 -45 75 -62 95 -62 40 0 72 33 72 74 0 27 -11 43 -67 98 -178 170 -431
                338 -677 449 -401 182 -864 259 -1301 218z m426 -629 c587 -60 1110 -375 1432
                -865 32 -49 85 -144 118 -210 262 -536 262 -1154 0 -1690 -193 -394 -498 -697
                -896 -890 -458 -222 -966 -253 -1454 -89 -731 246 -1239 900 -1303 1677 -43
                528 143 1062 508 1456 407 439 1009 670 1595 611z m-2201 -1424 c0 -3 -7 -34
                -15 -69 -22 -96 -45 -308 -45 -418 0 -147 27 -377 56 -486 l6 -23 -170 0 -170
                0 -16 88 c-48 265 -44 631 10 885 l6 27 169 0 c93 0 169 -2 169 -4z m4388 -53
                c30 -159 36 -234 36 -433 1 -201 -8 -306 -36 -452 l-11 -58 -169 0 -170 0 6
                25 c36 142 49 270 49 475 0 205 -13 333 -49 475 l-6 25 170 0 169 0 11 -57z"/>
                <path d="M2425 4244 c-175 -18 -331 -55 -485 -115 -63 -24 -88 -40 -123 -79
                -61 -68 -82 -153 -58 -238 5 -17 85 -163 177 -323 179 -309 193 -326 272 -336
                26 -3 65 5 132 29 88 30 104 33 220 32 115 0 132 -2 216 -32 50 -18 103 -32
                118 -32 39 0 87 23 117 56 31 35 331 555 347 603 29 84 3 183 -66 254 -56 58
                -248 126 -457 162 -90 16 -334 27 -410 19z m293 -154 c196 -22 442 -96 482
                -147 38 -49 30 -72 -135 -358 -86 -148 -159 -273 -163 -277 -4 -4 -39 3 -78
                17 -156 54 -337 58 -494 10 -47 -14 -91 -27 -99 -28 -9 -1 -41 43 -81 113
                -237 410 -250 434 -250 468 0 68 70 107 286 162 181 46 351 59 532 40z"/>
                <path d="M2464 3005 c-233 -51 -396 -286 -355 -514 36 -200 185 -348 382 -381
                295 -50 569 224 520 518 -32 189 -174 338 -360 378 -80 17 -107 16 -187 -1z
                m170 -150 c115 -30 193 -111 223 -230 15 -60 15 -70 0 -130 -30 -122 -110
                -202 -230 -231 -52 -13 -74 -14 -118 -5 -107 22 -191 91 -236 191 -24 53 -21
                173 5 232 37 83 127 157 217 179 48 11 77 10 139 -6z"/>
                <path d="M1024 2606 c-63 -29 -110 -85 -131 -156 -13 -47 -14 -66 -4 -135 43
                -285 172 -582 349 -805 95 -119 273 -285 347 -323 85 -43 201 -26 273 40 30
                27 90 122 206 323 90 157 167 299 170 317 14 63 -8 106 -94 183 -133 120 -196
                232 -235 419 -8 40 -24 86 -36 102 -41 59 -45 59 -436 59 -355 0 -358 0 -409
                -24z m730 -148 c47 -229 120 -367 265 -500 l70 -63 -159 -275 c-169 -293 -187
                -315 -248 -306 -63 9 -244 178 -355 329 -145 197 -228 385 -278 629 -17 84
                -18 110 -9 140 20 69 17 68 382 68 l328 0 4 -22z"/>
                <path d="M3292 2610 c-43 -26 -69 -72 -77 -134 -22 -164 -123 -342 -249 -437
                -58 -43 -90 -106 -83 -159 7 -55 317 -592 374 -647 72 -72 189 -91 278 -46
                101 51 312 265 416 421 150 224 239 449 279 706 21 134 -23 233 -129 288 l-53
                28 -362 0 c-343 0 -363 -1 -394 -20z m750 -143 c39 -27 50 -67 37 -146 -51
                -323 -200 -613 -439 -851 -158 -158 -216 -185 -278 -133 -12 10 -91 139 -176
                288 l-155 269 69 64 c84 76 148 164 194 264 35 76 66 178 66 220 0 49 3 49
                340 46 285 -3 318 -5 342 -21z"/>
            </g>
        </svg>
    );
}