// React:
import {Outlet} from "react-router-dom";

// Components:
import AdminTopBar from "../components/AdminTopBar";
import AdminNavBar from "../components/AdminNavBar";
import { Helmet } from "react-helmet";

export default function AdminBaseContainer() {
    return (
        <div className='admin-base-container'>
            <Helmet>
                <title>Panel Administratora</title>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"/>
            </Helmet>

            <AdminTopBar />
            <AdminNavBar />
            <Outlet />
        </div>
    );
}