export default function DoctorMaleIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M1862 5100 c-210 -55 -378 -226 -427 -432 -12 -50 -15 -147 -15 -468
                l0 -406 -45 -44 c-61 -60 -79 -120 -73 -244 3 -78 8 -99 31 -139 37 -63 88
                -95 172 -109 37 -6 68 -16 70 -22 51 -207 119 -351 237 -504 46 -59 53 -82 25
                -82 -22 0 -130 -60 -185 -102 -67 -52 -156 -164 -194 -244 -15 -33 -36 -98
                -46 -143 -10 -47 -24 -86 -32 -91 -8 -4 -145 -47 -305 -95 -159 -47 -313 -97
                -340 -109 -93 -42 -169 -126 -199 -222 -19 -58 -106 -560 -106 -609 0 -24 7
                -39 26 -54 34 -27 57 -26 89 2 24 20 30 42 76 307 27 157 53 298 59 314 16 47
                58 95 103 118 35 18 577 188 600 188 4 0 7 -34 7 -75 0 -65 -2 -75 -17 -75
                -42 0 -140 -58 -190 -113 -85 -94 -90 -113 -125 -560 -34 -437 -34 -443 31
                -508 42 -42 91 -59 173 -59 68 0 102 13 117 47 16 36 14 51 -14 78 -20 21 -34
                25 -88 25 -109 0 -103 -32 -69 406 33 419 35 429 106 491 46 41 95 56 171 51
                89 -6 151 -44 187 -118 26 -53 29 -72 56 -423 24 -313 26 -371 14 -388 -11
                -16 -25 -19 -81 -19 -58 0 -71 -3 -89 -23 -28 -30 -29 -78 -1 -103 17 -16 37
                -19 108 -19 75 0 92 3 131 27 65 37 90 87 90 176 0 95 -48 696 -60 755 -26
                127 -126 234 -253 272 l-42 12 -3 96 -3 96 88 26 c48 15 107 33 131 40 l42 13
                16 -36 c119 -279 349 -864 565 -1437 111 -294 133 -335 179 -335 46 0 68 41
                179 335 216 573 446 1158 565 1437 l16 36 42 -13 c24 -7 82 -25 131 -40 l87
                -26 -2 -221 -3 -220 -55 -20 c-269 -99 -340 -445 -132 -640 120 -112 280 -138
                426 -70 244 114 300 429 109 620 -50 50 -138 102 -175 102 -17 0 -18 16 -18
                200 0 110 3 200 8 200 22 0 564 -170 599 -188 44 -23 84 -68 102 -115 11 -28
                241 -1357 241 -1390 0 -10 -11 -29 -25 -42 l-24 -25 -2071 0 -2071 0 -24 25
                c-14 13 -25 32 -25 42 0 10 16 110 36 223 42 243 44 278 13 302 -35 29 -78 24
                -107 -13 -12 -16 -92 -456 -92 -507 0 -84 76 -186 156 -210 60 -18 4168 -18
                4228 0 79 24 156 126 156 209 0 43 -230 1373 -246 1423 -30 96 -106 180 -199
                222 -27 12 -180 62 -340 110 -159 47 -297 90 -306 95 -10 5 -20 32 -27 72 -19
                117 -78 233 -173 340 -46 52 -159 132 -224 156 -24 9 -47 19 -50 23 -3 3 23
                43 57 89 97 128 171 280 208 424 l18 70 68 12 c84 16 134 48 171 110 23 40 28
                61 31 139 6 124 -12 184 -73 244 l-45 44 0 148 c0 135 -2 150 -20 168 -27 27
                -81 26 -106 -1 -17 -19 -19 -41 -22 -188 l-3 -168 -52 -17 c-29 -10 -57 -20
                -64 -22 -9 -4 -13 76 -15 333 -3 298 -5 343 -21 377 -28 62 -80 119 -138 151
                -53 29 -57 30 -225 33 -194 4 -230 -2 -301 -53 -136 -99 -210 -99 -346 0 -71
                51 -107 57 -301 53 -168 -3 -172 -4 -225 -33 -58 -32 -110 -89 -138 -151 -16
                -34 -18 -79 -21 -377 -2 -257 -6 -337 -15 -333 -7 2 -36 13 -64 22 l-53 18 0
                422 c0 383 2 428 18 482 49 154 181 271 342 302 71 13 1189 13 1260 0 116 -22
                222 -91 285 -186 55 -83 67 -125 74 -268 6 -118 8 -130 30 -147 30 -25 76 -24
                101 1 18 18 20 33 20 133 0 201 -50 331 -174 452 -82 81 -164 128 -272 155
                -73 19 -111 20 -698 19 -578 0 -626 -1 -694 -19z m473 -690 c169 -107 281
                -107 450 0 l79 50 146 0 c172 0 201 -10 238 -80 22 -44 22 -50 22 -421 0 -364
                1 -377 20 -402 20 -26 85 -52 102 -41 4 3 8 -54 8 -127 0 -124 -2 -138 -35
                -238 -57 -169 -152 -319 -281 -442 -310 -293 -738 -293 -1048 0 -129 123 -224
                273 -281 442 -33 100 -35 114 -35 239 0 115 2 131 16 126 22 -8 78 18 98 45
                14 21 16 68 16 399 0 370 0 376 23 420 36 70 65 80 237 80 l146 0 79 -50z
                m-777 -810 c19 0 17 -189 -3 -196 -25 -10 -81 15 -94 42 -11 25 -16 156 -6
                166 3 3 25 2 48 -3 23 -4 48 -8 55 -9z m2112 -60 c0 -86 -13 -116 -57 -131
                -58 -19 -63 -11 -63 95 l0 94 53 9 c28 5 55 8 60 6 4 -2 7 -35 7 -73z m-1682
                -1144 c-3 -130 -5 -147 -25 -173 -11 -15 -27 -26 -35 -24 -51 15 -111 2 -360
                -75 -15 -4 -15 -1 -2 41 36 122 132 244 240 305 48 28 150 68 177 69 5 1 7
                -64 5 -143z m1203 128 c180 -53 308 -181 364 -362 12 -39 12 -42 -3 -38 -222
                69 -263 79 -322 80 -64 1 -65 2 -82 36 -14 28 -18 61 -18 168 0 72 1 132 3
                132 2 0 28 -7 58 -16z m-962 -120 c227 -94 479 -83 737 32 8 4 13 -27 16 -103
                3 -99 6 -113 36 -167 17 -33 31 -60 29 -61 -1 -1 -109 -81 -240 -178 -131 -97
                -242 -177 -247 -177 -5 0 -116 80 -247 177 -131 97 -239 177 -240 178 -2 1 12
                28 29 61 30 54 33 68 36 167 3 76 8 107 16 103 6 -3 40 -17 75 -32z m169 -727
                c10 -8 -17 -24 -110 -63 -68 -28 -126 -49 -129 -45 -3 3 -36 82 -73 175 -38
                93 -75 184 -82 202 -13 31 5 20 183 -112 109 -81 204 -151 211 -157z m718 269
                c-8 -18 -44 -109 -82 -202 -37 -93 -70 -172 -73 -175 -3 -4 -62 17 -130 45
                l-123 53 208 156 c115 85 210 156 212 156 1 1 -4 -14 -12 -33z m-475 -412 c46
                -20 86 -38 88 -40 2 -2 -14 -23 -35 -48 l-39 -45 -95 0 -95 0 -40 46 c-22 26
                -35 48 -30 50 11 4 160 71 161 72 0 1 38 -15 85 -35z m-338 -172 l54 -64 -14
                -71 c-7 -40 -15 -76 -16 -81 -2 -5 -27 52 -56 127 -68 178 -61 199 32 89z
                m592 58 c4 -6 -17 -72 -46 -147 -29 -75 -54 -132 -56 -127 -1 5 -9 41 -16 80
                l-14 72 56 66 c56 67 65 74 76 56z m886 -86 c122 -82 143 -256 42 -359 -92
                -95 -229 -100 -327 -13 -126 110 -95 317 59 390 62 29 168 21 226 -18z m-1152
                -136 c6 -29 22 -116 36 -193 l25 -140 -62 -167 c-35 -93 -65 -168 -68 -168 -3
                0 -33 75 -68 168 l-62 167 25 140 c14 77 30 164 36 193 l10 52 59 0 59 0 10
                -52z"/>
            </g>
        </svg>
    );
}