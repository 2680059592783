export default function FormField({value, placeholder, handleChange, error, type, styling={}}) {
    var field = <input className={'form-field__input' + (error ? ' form-field__input--error' : '')} value={value} placeholder={placeholder} onChange={handleChange} />;
    if (type === 'textarea') {
        field = <textarea className={'form-field__input form-field__textarea' + (error ? ' form-field__input--error' : '')} value={value} placeholder={placeholder} onChange={handleChange}></textarea>
    }

    return (
        <div className='form-field' style={styling}>
            {field}
            <label className='form-field__label'>{placeholder}</label>
        </div>
    );
}