// React:
import {useState, useContext, useEffect} from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';

// Components:
import FormFieldRegular from "./FormFieldRegular";
import LoginButton from "./LoginButton";

// Auth:
import { AuthContext } from "../pages/AdminUsersContainer";


export default function LoginBox() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [loginError, setLoginError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [credentialsError, setCredentialsError] = useState('');

    useEffect(() => {
        if (auth.status) {
            navigate('/admin/dashboard');
        }
    }, [auth.status, navigate]);

    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            login: login,
            password: password
        };

        const options = {
            withCredentials: true
        }

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/login`, data, options)
        .then(({data}) => {
            setLoginError('');
            setPasswordError('');
            setCredentialsError('');

            // Confirm authorization:
            auth.confirm();

            // Redirect to dashboard:
            navigate(location.state?.path || '/admin/dashboard');
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                // The request was well-formed but was unable to be followed due to semantic errors:
                if (error.response.status === 422) {
                    setLoginError(error.response.data.login?.msg);
                    setPasswordError(error.response.data.password?.msg);
                }

                // Wrong credentials:
                else if (error.response.status === 401) {
                    setLoginError('');
                    setPasswordError('');
                    setCredentialsError(error.response.data.credentials?.msg);
                }

                // Too many login attempts:
                else if (error.response.status === 429) {
                    setLoginError('');
                    setPasswordError('');
                    setCredentialsError('Zbyt dużo nieudanych prób logowania! Spróbuj ponownie później.');
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    return (
        <div className='admin-login__wrapper'>
            <div className='admin-login'>
                <label className='admin-login__title'>Panel Administratora</label>
                <form className='admin-login__form' onSubmit={handleSubmit}>
                    <FormFieldRegular value={login} placeholder='Login' handleChange={(e) => setLogin(e.target.value)} error={loginError} />
                    <FormFieldRegular value={password} placeholder='Hasło' handleChange={(e) => setPassword(e.target.value)} error={passwordError} type='password' />
                    <label className='admin-login__credentials-error'>{credentialsError}</label>
                    <LoginButton text='Zaloguj się' />
                </form>
            </div>

            <div className='admin-login__decoration'></div>
            <div className='admin-login__decoration admin-login__decoration--medium'></div>
        </div>
    );
}