// React:
import {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';

// Components:
import PricingRedirect from '../redirects/PricingRedirect';
import DropdownBox from "../components/DropdownBox";
import PriceTable from '../components/PriceTable';


export default function PricingPage() {
    const [services, setServices] = useState([]);
    const [notes, setNotes] = useState('');
    const params = useParams();

    const clinics =  useMemo(() => {return {
        'poradnia-chirurgii-stomatologicznej': 'pcs',
        'poradnia-stomatologiczna': 'ps',
        'poradnia-otorynolaryngologiczna': 'po',
        'pracownia-rentgenowska': 'pr'
    }}, []);

    useEffect(() => {
        var clinic = clinics[params.clinic];

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/service`, {params: {clinic: clinic}})
        .then(({data}) => {
            setServices(data);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch services [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/misc`, {params: {id: `${clinic}_notes`}})
        .then(({data}) => {
            setNotes(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch notes [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, [clinics, params.clinic]);

    // Return to default pricing page when url is incorrect:
    if (!Object.keys(clinics).includes(params.clinic)) {
        return <PricingRedirect />;
    }

    function titleCase(text) {
        var result = '';
        for (let word of text.split(' ')) {
            result += word.substring(0, 1).toUpperCase() + word.substring(1, word.length) + ' ';
        }
        return result;
    }

    const selected = titleCase(params.clinic.replaceAll('-', ' '));

    return (
        <div className='pricing-page'>
            <Helmet>
                <title>Cennik | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <label className='pricing-page__title'>Cennik</label>
            <div className='pricing-page__section'>
                <label className='pricing-page__info'>Wybierz poradnię, dla której chcesz wyświetlić cennik:</label>
                <DropdownBox selected={selected}/>
            </div>
            <div className='pricing-page__section'>
                <label className='pricing-page__subtitle'>Cennik świadczeń komercyjnych</label>
                <label className='pricing-page__clinic-name'>{selected}</label>
                <PriceTable services={services} />
                <p className='pricing-page__notes' dangerouslySetInnerHTML={{"__html" : notes}}></p>
            </div>
        </div>
    );
}