export default function DoctorFemaleIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2425 5114 c-158 -24 -279 -61 -399 -121 -159 -80 -213 -133 -185
                -185 33 -62 60 -61 173 6 106 64 209 106 321 132 117 28 360 25 468 -4 197
                -54 359 -145 491 -278 132 -132 223 -288 274 -474 l27 -95 3 -880 c3 -950 2
                -975 -49 -1129 -33 -98 -95 -225 -115 -233 -16 -6 -381 178 -424 215 l-35 30
                -3 278 -3 279 46 28 c134 84 239 204 310 351 71 149 78 187 82 471 5 294 14
                273 -123 312 -276 77 -472 243 -587 494 -41 90 -73 119 -131 119 -69 0 -97
                -26 -139 -126 -124 -299 -321 -460 -589 -481 -67 -5 -86 -10 -105 -29 -23 -23
                -23 -27 -23 -237 0 -317 30 -452 139 -618 55 -84 184 -212 255 -254 l46 -27 0
                -273 0 -273 -26 -32 c-31 -36 -417 -235 -440 -226 -17 7 -80 132 -111 221 -52
                153 -53 171 -53 1094 0 911 1 928 49 1081 20 62 70 168 121 256 21 36 22 44
                11 71 -14 36 -52 56 -87 47 -45 -11 -140 -180 -188 -333 -57 -181 -57 -178
                -53 -1163 3 -799 5 -911 20 -970 27 -109 55 -187 104 -286 25 -51 44 -95 42
                -97 -2 -2 -85 -45 -184 -97 -248 -129 -322 -201 -394 -386 l-26 -67 -3 -576
                c-3 -573 -3 -575 18 -602 28 -35 77 -37 108 -4 l22 23 0 545 c0 602 1 614 63
                718 54 91 111 132 338 246 114 58 210 105 214 105 3 0 5 -220 5 -490 l0 -489
                -35 -17 c-50 -23 -110 -95 -125 -149 -31 -111 12 -224 108 -281 63 -38 115
                -48 181 -33 124 26 215 156 195 279 -11 67 -62 141 -121 175 l-52 30 -1 526 0
                526 26 12 c15 6 29 9 32 7 3 -3 74 -209 158 -458 85 -249 215 -635 291 -857
                75 -223 145 -413 155 -423 23 -23 73 -23 96 0 19 19 192 516 192 550 0 35 -34
                66 -73 66 -19 0 -42 -6 -51 -13 -8 -7 -37 -78 -63 -157 -26 -79 -50 -144 -53
                -144 -5 0 -260 748 -260 763 0 9 520 10 520 0 0 -4 -10 -37 -21 -73 -26 -81
                -21 -113 22 -136 45 -23 82 -5 104 49 10 23 78 220 151 436 74 217 136 396
                138 398 2 2 15 -2 30 -8 l26 -12 0 -292 0 -292 -44 -17 c-60 -23 -131 -88
                -164 -150 l-27 -51 0 -421 0 -421 29 -37 c33 -44 78 -66 134 -66 95 0 104 125
                11 145 l-29 7 0 269 0 269 165 0 165 0 0 -270 0 -270 -27 -11 c-69 -26 -86
                -89 -35 -129 55 -44 156 1 194 86 18 41 19 67 16 437 l-3 392 -33 67 c-36 73
                -80 116 -154 151 l-48 22 0 252 c0 139 2 253 5 253 4 0 100 -47 214 -105 227
                -114 284 -155 338 -246 62 -104 63 -116 63 -719 l0 -547 23 -21 c33 -31 80
                -29 106 4 21 26 21 35 21 574 0 512 -1 552 -20 624 -25 97 -71 184 -134 253
                -64 71 -99 94 -291 193 -88 46 -162 85 -164 86 -2 2 17 45 42 96 49 99 66 146
                100 273 21 78 21 99 25 965 2 645 0 909 -8 975 -34 258 -154 498 -340 685
                -167 166 -367 275 -602 326 -71 15 -312 27 -373 18z m247 -1055 c117 -164 313
                -305 516 -370 l72 -23 0 -176 c0 -143 -4 -192 -20 -256 -111 -439 -584 -658
                -990 -458 -79 39 -110 62 -181 134 -73 72 -94 101 -132 180 -64 132 -78 214
                -75 419 l3 165 70 12 c213 37 399 166 531 369 30 46 64 107 76 135 l21 51 32
                -59 c17 -33 52 -88 77 -123z m-254 -1494 c46 -8 114 -15 151 -15 63 1 197 21
                233 35 15 6 17 -16 20 -252 l3 -258 28 -47 c30 -52 90 -105 157 -138 25 -12
                50 -27 57 -32 9 -7 -13 -82 -88 -301 -54 -160 -99 -292 -99 -294 0 -2 -143 -3
                -318 -3 l-319 0 -96 288 c-54 158 -97 292 -97 297 0 6 28 25 62 44 34 19 83
                54 109 78 74 69 79 94 79 381 0 221 2 244 16 238 9 -4 55 -13 102 -21z m998
                -1554 c46 -21 82 -71 91 -126 l6 -35 -168 0 -168 0 6 40 c15 110 128 169 233
                121z m-1592 -462 c24 -11 56 -63 56 -91 0 -29 -40 -76 -74 -88 -96 -31 -169
                89 -97 161 32 31 71 38 115 18z"/>
                <path d="M2353 3115 c-55 -23 -60 -89 -10 -131 62 -52 134 -79 217 -79 65 0
                85 5 142 33 77 38 108 71 108 117 0 70 -70 86 -146 33 -39 -28 -54 -33 -103
                -33 -47 0 -65 5 -102 30 -62 42 -71 45 -106 30z"/>
            </g>
        </svg>

    );
}