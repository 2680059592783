export default function CaptionedImage({src, title, caption, narrow=false}) {
    return (
        <div className={'captioned-image' + (narrow ? ' captioned-image--narrow' : '')}>
            <img className='captioned-image__image' src={src} alt='Zdjęcie z podpisem' />
            
            <div className='captioned-image__caption-group'>
                <label className='captioned-image__title'>{title}</label>
                <p className='captioned-image__caption'>{caption}</p>
            </div>
        </div>
    );
}