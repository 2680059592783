import {useState, useEffect} from 'react';
import SuccessIcon from '../svg/SuccessIcon';
import ErrorIcon from '../svg/ErrorIcon';

export default function NotificationBox({notifications, deleteNotification}) {
    const [boxes, setBoxes] = useState([]);

    useEffect(function () {
        var newBoxes = [];

        for (let n in notifications) {
            var attr = '';
            var icon = <SuccessIcon className='notification__icon' />;

            if (notifications[n].type === 'error') {
                attr = ' notification--error';
                icon = <ErrorIcon className='notification__icon' />;
            }

            newBoxes.push(
                <div key={n} className={'notification' + attr} onClick={() => deleteNotification(n)}>
                    <div className='notification__icon-box'>
                        {icon}
                    </div>
                    <p className='notification__message'>
                        {notifications[n].message}  
                    </p>
                    <div className='notification__timebar'></div>
                </div>
            );
        }
        
        setBoxes(newBoxes);

    }, [notifications, deleteNotification]);

    return (
        <div className='notification-box'>
            {boxes}
        </div>
    );
}