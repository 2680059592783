import SlidingButton from "./SlidingButton";

export default function TeamMemberCard({name, major, image, url}) {
    return (
        <div className='team-member'>
            <img className='team-member__image' src={image} alt={name} />
            <label className='team-member__name'>{name}</label>
            <label className='team-member__major'>{major}</label>
            <div className='team-member__separator'></div>
            <SlidingButton text='Dowiedz się więcej' url={url} />
        </div>
    );
}