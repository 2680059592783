// React:
import {Helmet} from 'react-helmet';

// Components:
import SlidingButton from '../components/SlidingButton';
import SlidingSelect from '../components/SlidingSelect';
import ImageCarousel from '../components/ImageCarousel';
import ContactForm from '../components/ContactForm';
import TeamMemberCard from '../components/TeamMemberCard';
import GoogleReviews from '../components/GoogleReviews';

// Images:
import Logo from '../img/logo.png';
import LogoGrayTriangle from '../img/logo-gray-triangle.png';
import Image1 from '../img/pcs.jpg';
import Image2 from '../img/ps.jpg';
import Image3 from '../img/po.jpg';
import Image4 from '../img/pr.jpg';
import Image5 from '../img/image4.jpg';
import Doctor1 from '../img/doctor-mw-square.jpg';
import Doctor2 from '../img/doctor-mgw-square.png';
import Doctor3 from '../img/doctor-kk-square.jpg';
import Doctor4 from '../img/doctor-ms-square.jpg';

// Icons:
import BrokenToothIcon from '../svg/BrokenToothIcon';
import HealingToothIcon from '../svg/HealingToothIcon';
import EarIcon from '../svg/EarIcon';
import RadiationIcon from '../svg/RadiationIcon';


export default function MainPage({addNotification}) {
    return (
        <div className='main-page'>
            <Helmet>
                <title>Strona główna | Specjalistyczne Centrum Medyczne</title>
            </Helmet>
            
            {/* Welcome section */}
            <div className='main-page__section main-page__section--row'>
                <div className='main-page__group'>
                    <label className='main-page__title main-page__title--normal'>Nasz Zespół tworzy grono<br/><b>Lekarzy Specjalistów</b></label>
                    <img className='main-page__logo' src={Logo} alt='Logo' />
                    <SlidingButton text='Zobacz nasze poradnie' url='/#poradnie' />
                </div>
                <ImageCarousel images={[Image1, Image2, Image3, Image4, Image5]} />
            </div>

            {/* Clinics section */}
            <div className='main-page__section main-page__section--wide' id='poradnie'>
                <div className='main-page__title-group main-page__title-group--left main-page__group--fixed main-page__group--mobile-center'>
                    <label className='main-page__title main-page__title--left'>Nasze poradnie</label>
                    <label className='main-page__subtitle main-page__subtitle--left main-page__subtitle--mobile-center'>Dowiedz się, jakie usługi świadczymy w poszczególnych poradniach. Zapoznaj się z cennikami.</label>
                </div>
                <div className='main-page__group main-page__group--wide main-page__group--margin-bottom main-page__group--hex'>
                    <div className='main-page__group main-page__group--left main-page__group--fixed main-page__group--mobile-center'>
                        <SlidingSelect Icon={BrokenToothIcon} text='Poradnia Chirurgii Stomatologicznej' url='/poradnie/poradnia-chirurgii-stomatologicznej' />
                        <SlidingSelect Icon={HealingToothIcon} text='Poradnia Stomatologiczna' url='/poradnie/poradnia-stomatologiczna' />
                        <SlidingSelect Icon={EarIcon} text='Poradnia Otorynolaryngologiczna' url='/poradnie/poradnia-otorynolaryngologiczna' />
                        <SlidingSelect Icon={RadiationIcon} text='Pracownia Rentgenowska' url='/poradnie/pracownia-rentgenowska' />
                    </div>
                    <img className='main-page__image-right-edge' src={Image5} alt='Zdjęcie' />
                </div>
            </div>

            {/* Implantology section */}
            <div className='main-page__section'>
                <div className='main-page__title-group'>
                    <label className='main-page__title'>Implantologia</label>
                    <label className='main-page__subtitle'>To najlepsza dostępna metoda odtwarzania braków zębowych</label>
                </div>
                <div className='main-page__group main-page__group--row main-page__group--space-around'>
                    <iframe title="Zahn-Implantation in 3D -- CAMLOG" className='main-page__youtube-video' src='https://www.youtube.com/embed/ZxvcBRWwFMU'></iframe>
                    <div className='main-page__label-wrapper'>
                        <p className='main-page__info'>
                            W naszej praktyce stosujemy najwyższej jakości niemieckie implanty klasy premium - CONELOG by Camlog. Ponadto dzięki naszym specjalistom - chirurgii stomatologicznej możemy zaoferować pacjentom znacznie więcej aniżeli tylko wszczepienie implantu. Oferujemy całą gamę bardziej złożonych i specjalistycznych zabiegów rekonstrukcyjnych w obrębie kości szczęki i żuchwy umożliwiających pacjentom przygotowanie chirurgiczne do leczenia implantologicznego.
                        </p>
                        <img className='main-page__label-image' src={LogoGrayTriangle} alt='Logo szare' />
                    </div>
                </div>
                <SlidingButton text='Więcej o implantologii' url='/implantologia' />
            </div>

            {/* Contact section */}
            <div className='main-page__section' id='kontakt'>
                <ContactForm addNotification={addNotification} />
            </div>

            {/* Team section */}
            <div className='main-page__section main-page__section--wide' id='zespół'>
                <div className='main-page__title-group'>
                    <label className='main-page__title'>Nasz Zespół</label>
                    <label className='main-page__subtitle'>Poznaj specjalistów, którzy tworzą nasz Zespół</label>
                </div>
                <div className='main-page__group main-page__group--row main-page__group--wide main-page__group--hex main-page__group--team-members'>
                    <TeamMemberCard image={Doctor1} name='dr n. med. Maciej Wrzoł' major='specjalista chirurgii stomatologicznej' url='/zespół/maciej-wrzoł' />
                    <TeamMemberCard image={Doctor2} name='dr n. med. Marta Gamrot-Wrzoł' major='specjalista otorynolaryngologii' url='/zespół/marta-gamrot-wrzoł' />
                    <TeamMemberCard image={Doctor3} name='lek. dent. Krzysztof Krasiński' major='specjalista stomatologii zachowawczej z endodoncją' url='/zespół/krzysztof-krasiński' />
                    <TeamMemberCard image={Doctor4} name='lek. dent. Magdalena Śpiewok' major='specjalista protetyki stomatologicznej' url='/zespół/magdalena-śpiewok' />
                </div>
            </div>

            {/* Google reviews section */}
            <div className='main-page__section main-page__section--wide main-page__section--no-side-padding'>
                <div className='main-page__title-group'>
                    <label className='main-page__title'>Opinie</label>
                    <label className='main-page__subtitle'>Sprawdź, co piszą o nas nasi klienci</label>
                </div>
                <GoogleReviews />
                <SlidingButton text='Zobacz wszystkie opinie' newPage={true} url='https://www.google.com/maps/place/SPECJALISTYCZNE+CENTRUM+MEDYCZNE+-+Specjalistyczna+Przychodnia+Lekarsko+Stomatologiczna+M.M.+Wrzo%C5%82+Sp%C3%B3%C5%82ka+Jawna/@50.0466718,18.5999183,17z/data=!3m1!5s0x47114dca0552f34b:0x4c6205d7107b5fed!4m8!3m7!1s0x47114d7f0d9471eb:0x46f06bcec3d48660!8m2!3d50.0466684!4d18.6024932!9m1!1b1!16s%2Fg%2F11h7n9kpk0?entry=ttu' />
            </div>
        </div>
    );
}