// React:
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import DoctorInfo from '../components/DoctorInfo';
import MediumImage from '../components/MediumImage';
import Portrait from '../img/doctor-ms.jpg';

export default function DoctorMWPage() {
    const [content, setContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/team`, {params: {id: 'ms'}})
        .then(({data}) => {
            setContent(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch doctor info [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    const name = 'lek. dent. Magdalena Śpiewok';
    const major = 'Specjalista protetyki stomatologicznej';
    const type = 'female';

    return (
        <div className='doctor-page'>
            <Helmet>
                <title>lek. dent. Magdalena Śpiewok | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <MediumImage src={Portrait} alt={name} />
            <DoctorInfo name={name} major={major} content={content} type={type} />
        </div>
    );
}