export default function PhoneIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M880 5113 c-94 -16 -413 -169 -520 -251 -237 -179 -374 -472 -357
                -762 8 -120 66 -359 152 -615 208 -624 521 -1181 967 -1717 130 -157 487 -513
                646 -645 253 -211 536 -409 794 -557 158 -90 444 -229 603 -295 319 -130 762
                -257 935 -268 299 -18 607 132 778 380 55 80 184 332 214 417 60 175 12 378
                -122 510 -42 41 -154 113 -451 291 -216 130 -412 246 -435 257 -149 75 -342
                64 -489 -30 -66 -42 -137 -126 -214 -254 -113 -190 -120 -194 -245 -144 -542
                220 -1090 681 -1443 1215 -154 234 -303 542 -289 599 9 36 33 56 170 137 128
                77 212 148 254 214 94 147 105 340 30 489 -11 23 -127 219 -257 435 -257 429
                -295 479 -407 539 -97 50 -216 71 -314 55z m202 -223 c26 -12 65 -39 85 -59
                48 -47 505 -807 528 -879 45 -137 -25 -287 -172 -369 l-39 -21 -219 366 c-121
                202 -234 382 -251 400 -44 44 -93 45 -136 2 -54 -55 -48 -69 206 -493 l227
                -378 -32 -36 c-53 -61 -74 -118 -73 -203 0 -68 5 -87 53 -197 101 -233 277
                -521 457 -748 113 -141 418 -446 559 -559 227 -180 500 -347 740 -453 120 -53
                136 -57 205 -57 85 -1 142 20 203 73 l36 32 640 -383 c352 -211 640 -386 641
                -389 1 -20 -98 -135 -152 -178 -195 -154 -403 -192 -678 -124 -189 47 -407
                117 -590 190 -967 381 -1807 1062 -2391 1937 -316 472 -538 963 -681 1505 -37
                138 -42 169 -42 271 -1 133 15 206 71 317 43 87 103 162 180 226 56 47 337
                197 418 224 55 18 150 10 207 -17z m2875 -3196 c70 -24 830 -482 874 -527 59
                -60 83 -120 84 -207 0 -67 -4 -83 -40 -158 l-40 -82 -637 382 -636 382 21 39
                c85 152 232 219 374 171z"/>
                <path d="M683 4685 c-91 -39 -59 -185 42 -185 105 0 132 155 33 189 -38 13
                -36 13 -75 -4z"/>
            </g>
        </svg>
    );
}