// React:
import {useEffect, useContext} from 'react';
import {useNavigate, Navigate, useLocation} from 'react-router-dom';
import axios from 'axios';

// Auth:
import {AuthContext} from '../pages/AdminUsersContainer';

export default function RequireAuth({children}) {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/is-authenticated`, {}, {withCredentials: true})
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                auth.deny();
                navigate('/admin/login', {state: {path: location.pathname}});
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        })
    });

    return (auth.status) ? children : <Navigate to='/admin/login' replace={true} state={{path: location.pathname}} />;
}