export default function BrokenToothIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M931 4366 c-13 -7 -30 -24 -37 -37 -37 -67 4 -130 101 -154 104 -26
                179 -70 277 -162 224 -209 423 -209 643 -3 89 85 165 129 262 154 71 18 209
                21 285 5 68 -14 202 -79 252 -122 l39 -33 -79 -28 c-378 -134 -639 -451 -700
                -849 -30 -196 3 -413 90 -594 65 -134 117 -206 225 -313 127 -125 242 -193
                442 -263 14 -5 5 -42 -67 -269 -46 -145 -107 -341 -136 -437 -55 -184 -84
                -241 -142 -286 -108 -82 -287 -10 -315 126 -6 30 -25 187 -41 349 -17 163 -34
                309 -39 325 -17 62 -64 140 -112 186 -177 172 -468 148 -615 -52 -64 -87 -72
                -123 -110 -486 -41 -401 -45 -413 -154 -462 -78 -36 -158 -25 -213 27 -51 48
                -76 105 -146 333 -94 303 -197 602 -282 822 -166 425 -161 394 -157 970 l4
                452 27 88 c15 48 43 119 62 157 44 86 45 122 6 161 -40 39 -100 41 -137 4 -29
                -29 -82 -138 -113 -235 -47 -142 -53 -223 -49 -716 l4 -459 27 -103 c15 -57
                63 -194 106 -305 123 -316 211 -568 317 -917 75 -244 104 -306 179 -382 149
                -150 373 -159 544 -20 55 45 116 137 130 200 6 26 27 192 45 369 31 298 36
                326 60 368 46 78 147 119 234 95 52 -13 120 -72 139 -119 8 -19 30 -179 49
                -361 19 -180 41 -347 49 -371 39 -109 113 -190 223 -242 62 -30 75 -32 172
                -32 98 0 109 2 165 32 71 37 138 101 182 175 20 34 67 167 132 378 55 179 120
                386 143 460 l44 135 119 7 c73 4 159 17 220 33 112 28 271 103 354 166 l54 41
                93 -93 94 -94 -54 -55 c-30 -30 -59 -66 -66 -79 -29 -56 -21 -65 408 -492 465
                -463 452 -454 602 -454 104 1 175 29 245 100 71 71 100 141 100 245 0 151 0
                151 -463 613 -477 477 -437 453 -557 336 l-76 -73 -89 89 c-50 49 -90 93 -90
                97 0 4 20 35 43 67 140 190 215 475 187 713 -38 326 -202 598 -466 774 -160
                107 -324 162 -509 171 l-111 6 -29 39 c-94 126 -265 237 -429 278 -90 23 -255
                27 -352 9 -143 -26 -280 -99 -400 -212 -82 -76 -122 -99 -178 -99 -62 0 -88
                14 -184 101 -132 118 -227 174 -353 206 -69 18 -97 18 -128 1z m2287 -546
                c160 -38 298 -118 413 -237 110 -113 180 -236 220 -388 30 -114 30 -288 1
                -410 -95 -386 -432 -648 -832 -648 -457 0 -816 331 -855 789 -13 153 34 356
                114 493 117 198 315 346 538 400 110 27 290 28 401 1z m1697 -2528 c23 -109
                -72 -208 -182 -188 -33 7 -79 49 -385 354 -192 191 -348 351 -348 356 0 5 46
                56 102 113 l103 104 351 -353 c280 -281 353 -360 359 -386z"/>
                <path d="M2724 3620 c-33 -13 -54 -50 -54 -93 0 -35 8 -47 90 -132 l90 -93 0
                -130 0 -130 -39 -49 c-21 -26 -75 -91 -120 -143 -108 -127 -115 -143 -119
                -268 -5 -127 0 -147 41 -172 45 -28 81 -25 119 9 32 29 33 32 37 122 l3 94
                123 146 c67 81 128 163 135 183 6 20 14 36 16 36 17 0 103 -52 108 -65 3 -9 6
                -58 6 -108 0 -51 5 -109 11 -129 18 -67 103 -128 176 -128 10 0 32 -14 49 -31
                42 -44 80 -62 119 -55 78 15 104 101 52 169 -36 48 -104 94 -160 109 l-45 12
                -4 105 c-6 163 -35 205 -204 294 l-99 52 -8 65 c-11 95 -36 135 -146 243 -102
                101 -121 110 -177 87z"/>
                <path d="M503 4300 c-31 -13 -57 -57 -57 -98 0 -47 49 -92 100 -92 50 0 94 45
                94 95 0 47 -13 71 -50 90 -32 17 -54 18 -87 5z"/>
            </g>
        </svg>
    );
}