import AdminChangePassword from "../components/AdminChangePassword";


export default function AdminAccountPage() {
    return (
        <div className='admin-base-page'>
            <label className='admin-base-page__title'>Ustawienia konta</label>
            <AdminChangePassword />
        </div>
    );
}