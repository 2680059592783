import {useState} from 'react';
import ArrowDownIcon from "../svg/ArrowDownIcon";
import BrokenToothIcon from '../svg/BrokenToothIcon';
import HealingToothIcon from '../svg/HealingToothIcon';
import EarIcon from '../svg/EarIcon';
import RadiationIcon from '../svg/RadiationIcon';

export default function DropdownBox({selected}) {
    const [optionsActive, setOptionsActive] = useState(false);

    function handleDropdownSelectorClick() {
        const options = document.getElementById('js-options');

        if (optionsActive) {
            options.setAttribute('class', 'dropdown-box__options');
        }

        else {
            options.setAttribute('class', 'dropdown-box__options dropdown-box__options--active');
        }

        setOptionsActive(!optionsActive);
    }

    return (
        <div className='dropdown-box'>
            <div className='dropdown-box__selector' onClick={handleDropdownSelectorClick}>
                <div className='dropdown-box__selector-label-box'>
                    <label className='dropdown-box__selector-label'>{selected}</label>
                </div>
                <div className='dropdown-box__selector-icon-box'>
                    <ArrowDownIcon className='dropdown-box__arrow-up' />
                    <ArrowDownIcon className='dropdown-box__arrow-down' />
                </div>
            </div>

            <div className='dropdown-box__options' id='js-options'>
                <a className='dropdown-box__option' href='/cennik/poradnia-chirurgii-stomatologicznej'>
                    <div className='dropdown-box__option-icon-box'>
                        <BrokenToothIcon className='dropdown-box__option-icon' />
                    </div>
                    <div className='dropdown-box__option-label-box'>
                        <label className='dropdown-box__option-label'>Poradnia Chirurgii Stomatologicznej</label>
                    </div>
                </a>
                <a className='dropdown-box__option' href='/cennik/poradnia-stomatologiczna'>
                    <div className='dropdown-box__option-icon-box'>
                        <HealingToothIcon className='dropdown-box__option-icon' />
                    </div>
                    <div className='dropdown-box__option-label-box'>
                        <label className='dropdown-box__option-label'>Poradnia Stomatologiczna</label>
                    </div>
                </a>
                <a className='dropdown-box__option' href='/cennik/poradnia-otorynolaryngologiczna'>
                    <div className='dropdown-box__option-icon-box'>
                        <EarIcon className='dropdown-box__option-icon' />
                    </div>
                    <div className='dropdown-box__option-label-box'>
                        <label className='dropdown-box__option-label'>Poradnia Otorynolaryngologiczna</label>
                    </div>
                </a>
                <a className='dropdown-box__option' href='/cennik/pracownia-rentgenowska'>
                    <div className='dropdown-box__option-icon-box'>
                        <RadiationIcon className='dropdown-box__option-icon' />
                    </div>
                    <div className='dropdown-box__option-label-box'>
                        <label className='dropdown-box__option-label'>Pracownia Rentgenowska</label>
                    </div>
                </a>
            </div>
        </div>
    );
}