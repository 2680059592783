import {useState, useRef, useEffect, useContext} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';

import { AuthContext } from '../pages/AdminUsersContainer';
import LogoutIcon from "../svg/LogoutIcon";
import RefreshIcon from "../svg/RefreshIcon";

export default function AdminTopBar() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState('unknown');
    const [sessionTimeLeft, setSessionTimeLeft] = useState('00:00:00');

    const expiryTime = useRef(0);

    function timeToString(time) {
        time = Math.round(time / 1000);
        var seconds, minutes, hours = 0;
        
        hours = Math.floor(time / 3600);
        time = time - hours * 3600;
        hours = hours.toString();

        minutes = Math.floor(time / 60);
        time = time - minutes * 60;
        minutes = minutes.toString();

        seconds = time;
        seconds = seconds.toString();

        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }

    useEffect(() => {
        const options = {
            withCredentials: true
        }

        var intervalId = setInterval(() => {
            if (expiryTime.current === null) {
                setSessionTimeLeft('00:00:00');
            }
            else if (expiryTime.current - Date.now() < 0) {
                setSessionTimeLeft('00:00:00');
                auth.deny();
                navigate('/admin/login', {state: {path: location.pathname}});
            }
            else {
                setSessionTimeLeft(timeToString(expiryTime.current - Date.now()));
            }
        }, 500);

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/get-user-details`, options)
        .then(({data}) => {
            setUsername(data.name);
            setSessionTimeLeft(timeToString(new Date(data.exp*1000) - Date.now()));
            expiryTime.current = data.exp * 1000;
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch user details [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
        
        return () => clearInterval(intervalId);
    }, [auth, location.pathname, navigate]);

    function handleLogout() {
        const options = {
            withCredentials: true
        }

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/logout`, {}, options)
        .then(({data}) => {
            auth.deny();
            navigate('/admin/login');
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to log out the user [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    function handleSessionRenewal() {
        const options = {
            withCredentials: true
        };

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admins/renew-session`, {}, options)
        .then(({data}) => {
            expiryTime.current = data.exp * 1000;
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to renew the session [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    return (
        <div className='admin-top-bar'>
            <div className='admin-top-bar__left-container'>
                <label className='admin-top-bar__title'>Panel Administratora</label>
            </div>
            <div className='admin-top-bar__right-container'>
                <div className='admin-top-bar__subcontainer'>
                    <label className='admin-top-bar__info'><b>{username}</b></label>
                    <hr className='admin-top-bar__separator' />
                    <label className='admin-top-bar__info'>Sesja wygaśnie za: <span className='admin-top-bar__number'>{sessionTimeLeft}</span></label>
                </div>
                
                <hr className='admin-top-bar__separator admin-top-bar__separator--mobile-hidden' />
                
                <div className='admin-top-bar__subcontainer'>
                    <div className='admin-top-bar__button' onClick={handleSessionRenewal}>
                        <RefreshIcon className='admin-top-bar__icon' />
                    </div>
                    <hr className='admin-top-bar__separator' />
                    <div className='admin-top-bar__button' onClick={handleLogout}>
                        <LogoutIcon className='admin-top-bar__icon' />
                    </div>
                </div>
            </div>
        </div>
    );
}