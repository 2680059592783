import {useState, useEffect} from 'react';
import axios from 'axios';
import Review from './Review';

export default function GoogleReviews() {
    const [reviews, setReviews] = useState(
        Array.from({length: 10}, (item, i) => {
                return <Review key={i} name='...' rating={0} pictureMeta={null} content={'Trwa wczytywanie opinii. Prosimy o cierpliwość...'} />
            }
        )
    );

    // Get last 10 Google Reviews from Express API:
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/google-reviews`, {}, {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        })
        .then(({data}) => {
            const newReviews = Array.from({length: 10}, (item, i) => {
                return <Review key={i} name={data[i].name} rating={data[i].rating} pictureUrl={data[i].pictureUrl} content={data[i].review} />
            });
            setReviews(newReviews);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API [${error.response.status}]:`, error.response.data);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    return (
        <div className='google-reviews'>
            <div className='google-reviews__fade-box'></div>
            <div className='google-reviews__fade-box google-reviews__fade-box--left'></div>
            <div className='google-reviews__wrapper'>
                <div className='google-reviews__box'>
                    {reviews}
                </div>
                <div className='google-reviews__box google-reviews__box--secondary'>
                    {reviews}
                </div>
            </div>
        </div>
    );
}