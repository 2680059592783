export default function EmailIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M4850 4556 c-116 -50 -1613 -775 -1631 -790 -43 -36 -9 -126 47 -126
                12 0 323 145 690 321 368 177 739 355 823 395 150 72 176 79 186 52 4 -11
                -317 -1008 -1081 -3356 -58 -177 -110 -322 -116 -322 -17 0 -1499 873 -1496
                881 2 4 435 435 963 958 528 522 966 963 974 979 32 69 -22 152 -99 152 -34 0
                -72 -25 -357 -233 -1723 -1261 -2181 -1596 -2199 -1607 -21 -13 -38 -10 -679
                115 -66 13 -250 49 -410 80 -159 32 -296 61 -304 65 -11 6 12 21 75 53 94 47
                1932 930 2564 1232 124 59 233 114 243 123 24 21 23 84 -2 106 -10 10 -32 17
                -47 18 -18 0 -568 -259 -1479 -697 -797 -383 -1456 -702 -1463 -708 -24 -19
                -52 -85 -52 -120 0 -49 39 -117 80 -138 20 -10 340 -78 730 -154 382 -75 695
                -137 695 -138 1 -1 118 -252 260 -557 142 -305 265 -565 272 -577 8 -12 28
                -25 45 -28 27 -6 47 4 177 91 80 55 154 107 163 117 23 22 23 79 1 100 -41 36
                -65 32 -153 -28 -45 -31 -84 -54 -87 -52 -4 5 26 690 31 695 3 3 515 -295 529
                -308 5 -4 -39 -39 -96 -78 -58 -39 -107 -79 -111 -88 -12 -31 -6 -61 17 -82
                40 -38 69 -29 211 69 l133 90 413 -242 c388 -228 416 -243 461 -242 57 1 112
                31 134 74 8 15 286 860 616 1876 656 2018 620 1891 566 1972 -30 44 -97 81
                -148 81 -19 -1 -59 -11 -89 -24z m-1977 -2134 l-802 -797 -5 -70 c-3 -38 -11
                -194 -17 -345 -6 -151 -11 -279 -13 -283 -1 -5 -88 177 -194 404 -140 300
                -189 414 -180 420 15 9 594 433 1398 1023 333 245 607 445 610 445 3 0 -356
                -359 -797 -797z"/>
            </g>
        </svg>
    );
}