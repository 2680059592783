export default function PinIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2370 5109 c-699 -81 -1278 -584 -1455 -1264 -75 -289 -70 -630 12
                -915 99 -345 264 -611 817 -1315 108 -137 196 -253 196 -256 0 -4 -23 -10 -52
                -13 -260 -35 -616 -159 -784 -274 -84 -57 -180 -161 -212 -229 -23 -49 -27
                -71 -27 -143 1 -108 29 -174 112 -261 142 -150 360 -257 688 -338 286 -71 520
                -96 900 -95 312 0 471 12 705 54 495 90 855 277 958 497 22 48 26 72 27 143 0
                109 -28 174 -117 267 -75 78 -140 124 -268 188 -152 77 -454 167 -637 191 -29
                3 -53 9 -53 13 0 3 111 147 245 320 450 575 607 818 721 1111 77 202 109 382
                108 625 0 210 -15 317 -65 488 -234 787 -1007 1299 -1819 1206z m485 -219
                c526 -111 953 -485 1123 -985 59 -172 76 -280 76 -485 1 -279 -44 -465 -173
                -725 -124 -251 -219 -387 -699 -1004 -173 -222 -382 -494 -464 -605 -82 -110
                -153 -201 -158 -201 -4 0 -89 109 -188 241 -99 133 -287 378 -418 545 -466
                595 -605 793 -719 1023 -73 148 -114 264 -146 416 -33 153 -33 449 0 600 100
                468 380 834 801 1049 150 77 311 126 490 151 106 15 361 4 475 -20z m-607
                -3932 c87 -117 177 -241 201 -274 55 -80 103 -102 158 -71 12 7 78 89 145 182
                68 94 159 217 202 274 l78 103 57 -7 c118 -14 358 -67 479 -106 257 -84 452
                -212 483 -317 79 -272 -662 -542 -1491 -542 -635 0 -1237 156 -1437 373 -88
                95 -82 173 21 272 135 130 417 240 783 304 174 31 141 51 321 -191z"/>
                <path d="M2521 4706 c-90 -50 -50 -186 55 -186 38 0 94 59 94 100 0 23 -9 42
                -34 66 -37 37 -73 43 -115 20z"/>
                <path d="M2898 4621 c-27 -24 -33 -36 -33 -69 0 -51 29 -83 93 -107 249 -92
                482 -310 597 -558 63 -135 105 -319 105 -454 0 -49 4 -59 34 -89 47 -47 86
                -46 133 1 l36 36 -6 107 c-25 435 -257 821 -630 1047 -91 55 -230 115 -269
                115 -17 0 -41 -12 -60 -29z"/>
                <path d="M2415 4104 c-109 -21 -243 -92 -331 -176 -231 -220 -288 -533 -148
                -818 39 -79 62 -110 133 -181 71 -71 102 -94 181 -133 206 -101 411 -101 620
                1 79 38 109 60 181 132 72 72 94 102 132 181 102 209 102 411 0 620 -38 79
                -60 109 -132 181 -71 71 -102 94 -181 133 -148 72 -294 92 -455 60z m247 -195
                c92 -19 177 -66 249 -138 199 -199 199 -503 0 -702 -71 -70 -138 -109 -236
                -134 -304 -78 -615 167 -615 485 0 225 164 433 383 486 66 16 152 17 219 3z"/>
            </g>
        </svg>
    );
}