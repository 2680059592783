export default function InfoIcon({className}) {
   return (
      <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
         <path d="m 0.51471145,278.56832 c 5.80000005,54.7 28.09999955,104.9 65.29999955,146.5 53.699999,60.2 132.399999,92.5 209.199999,86 38.1,-3.3 69.5,-11.9 102.2,-28.2 l 14.2,-7.1 54.3,18.1 c 29.9,10 55.4,18.1 56.7,18.1 3,0 9.1,-6.1 9.1,-9.1 0,-1.3 -8.1,-26.8 -18.1,-56.7 l -18.1,-54.3 7.1,-14.2 c 51.7,-103.6 33.3,-220.6 -47.3,-301.300005 -41.4,-41.3 -90.3,-65.8 -149.1,-74.4999998 -13.3,-2.00000002 -50.1,-1.80000002 -63.9,0.4 -57.6,9.0999998 -105.4,33.2999998 -146.199999,74.0999998 -40.5,40.600005 -65,88.700005 -73.8999995,145.300005 -1.80000005,11.6 -2.70000005,45.4 -1.50000005,56.9 z m 21.89999955,-58.1 c 8.7,-52.2 32.2,-96.4 71.2,-134.000005 36.199999,-34.9 78.299999,-55.8 129.399999,-64.1 8.2,-1.4 16.5,-1.8 32.5,-1.8 16,0 24.3,0.4 32.5,1.8 51.1,8.3 93.2,29.2 129.4,64.1 65.2,62.900005 88.7,148.900005 64.6,236.800005 -5.6,20.5 -12.1,36.2 -24.5,58.7 -1.6,3 -3,6.6 -3,8 0,1.4 7,23.4 15.5,48.9 8.5,25.6 15.3,46.6 15.2,46.8 -0.2,0.1 -21.2,-6.7 -46.8,-15.2 -25.5,-8.5 -47.5,-15.5 -48.9,-15.5 -1.4,0 -5,1.4 -8,3 -22.5,12.4 -38.2,18.9 -58.7,24.5 -62,17 -123.9,10.4 -178.1,-19.1 -34.1,-18.6 -66.799999,-49.3 -88.399999,-82.9 -19.8,-30.7 -32.8,-68.9 -36.2,-106.5 -1,-10.8 0.4,-41.8 2.3,-53.5 z"/>
         <path d="m 246.3,107.5 c -15.9,4.3 -26.5,15.9 -29.5,32.5 -2.2,11.6 2.3,25.2 11.2,34 8.8,8.9 22.4,13.4 34,11.2 17.8,-3.3 29.9,-15.4 33.2,-33.2 2.2,-11.5 -2.3,-25.2 -11,-33.9 -5.6,-5.6 -10.7,-8.5 -18.9,-10.6 -7,-1.8 -12.1,-1.8 -19,0 z m 20.3,21.7 c 1.9,1.3 4.7,4.1 6.2,6.2 2.3,3.3 2.7,5 2.7,10.6 0,5.6 -0.4,7.3 -2.7,10.6 -4.6,6.5 -9.1,8.9 -16.8,8.9 -5.4,0 -7.3,-0.5 -10.3,-2.5 -7.7,-5.2 -11.1,-14 -8.8,-22.7 1.3,-4.8 7.9,-11.9 12.5,-13.3 4.9,-1.5 13.3,-0.4 17.2,2.2 z" />
         <path d="m 418.4,126.9 c -3.2,1.4 -6.4,6 -6.4,9.2 0,1.7 1.1,4.1 2.9,6.1 6,6.7 16.6,2.8 16.6,-6.2 0,-6.6 -7.2,-11.6 -13.1,-9.1 z" />
         <path d="m 438.6,161.5 c -6.6,3.5 -7.1,7.8 -2.2,19.3 14,32.8 18.7,70.1 13.1,104.7 -2.1,12.5 -2,13.2 1.9,17.1 3.6,3.6 6.7,4.2 10.9,2.3 3.7,-1.7 5.4,-5.6 7.2,-16.9 6.1,-38.3 0.2,-82.7 -15.5,-116.6 -3.3,-7.2 -4.4,-8.7 -7.4,-9.9 -4.3,-1.8 -4.4,-1.8 -8,0 z" />
         <path d="m 202.4,207 c -1.2,0.4 -3.1,2.1 -4.3,3.6 -2,2.5 -2.1,3.7 -2.1,25.5 V 259 l 2.9,3.2 c 2.6,3 3.5,3.3 10,3.6 l 7.1,0.4 v 39.8 39.8 l -7.1,0.4 c -6.5,0.3 -7.4,0.6 -10,3.6 L 196,353 v 23 23 l 2.9,3.2 2.9,3.3 53.6,0.3 53.5,0.3 3.3,-3 3.3,-2.9 V 376 351.8 l -3.3,-2.9 c -2.9,-2.6 -4,-2.9 -9.7,-2.9 H 296 l -0.2,-67.1 -0.3,-67.1 -3.3,-2.9 -3.2,-2.9 -42.3,0.1 c -23.2,0 -43.2,0.4 -44.3,0.9 z M 276,292.5 V 359 l 2.9,3.2 c 2.6,3 3.5,3.3 10,3.6 l 7.1,0.4 v 9.9 9.9 h -40 -40 v -10 -10 h 6.5 c 5.7,0 6.8,-0.3 9.7,-2.9 l 3.3,-2.9 V 306 251.8 l -3.3,-2.9 c -2.9,-2.6 -4,-2.9 -9.7,-2.9 H 216 v -10 -10 h 30 30 z" />
      </svg>
   );
}