export default function InstagramIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M1100 5105 c-287 -43 -514 -157 -721 -364 -213 -213 -329 -451 -368
                -750 -15 -117 -15 -2745 0 -2862 39 -299 155 -537 368 -750 213 -213 451 -329
                750 -368 117 -15 2745 -15 2862 0 299 39 537 155 750 368 213 213 329 451 368
                750 15 117 15 2745 0 2862 -39 299 -155 537 -368 750 -213 213 -451 329 -750
                368 -128 17 -2778 13 -2891 -4z m2997 -452 c113 -39 138 -52 231 -116 148
                -102 265 -261 324 -440 l33 -102 0 -1435 c0 -1373 -1 -1438 -19 -1495 -67
                -217 -183 -378 -354 -494 -74 -50 -138 -79 -242 -112 l-75 -24 -1435 0 -1435
                0 -102 33 c-280 92 -476 297 -569 597 -18 57 -19 122 -19 1495 0 1373 1 1438
                19 1495 71 228 195 394 380 510 78 49 212 101 295 115 25 4 683 7 1461 6
                l1415 -1 92 -32z"/>
                <path d="M3880 4256 c-71 -16 -112 -38 -160 -87 -65 -67 -85 -120 -85 -224 0
                -78 4 -97 27 -140 55 -104 148 -165 264 -173 85 -5 130 7 201 55 199 134 176
                439 -40 542 -74 35 -133 43 -207 27z"/>
                <path d="M2396 3829 c-483 -67 -895 -403 -1045 -855 -51 -154 -65 -243 -65
                -414 0 -171 14 -260 65 -415 121 -362 433 -673 797 -795 152 -50 242 -64 412
                -64 170 0 260 14 412 64 364 122 676 433 797 795 51 155 65 244 65 415 0 224
                -43 407 -140 590 -185 347 -503 586 -889 665 -96 20 -314 28 -409 14z m421
                -458 c107 -35 136 -49 218 -104 168 -113 281 -270 346 -477 20 -65 24 -96 24
                -230 0 -165 -12 -226 -71 -357 -74 -168 -249 -343 -418 -418 -130 -58 -191
                -70 -356 -70 -165 0 -226 12 -356 70 -169 75 -344 250 -418 418 -59 131 -71
                192 -71 357 0 134 4 165 24 230 34 108 64 171 121 254 113 164 271 277 465
                334 99 29 114 31 265 27 119 -3 146 -7 227 -34z"/>
            </g>
        </svg>
    );
}