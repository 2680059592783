// React:
import { useState } from "react";

// Icons:
import EyeOpened from "../svg/EyeOpened";
import EyeClosed from "../svg/EyeClosed";


export default function FormFieldRegular({value, placeholder, handleChange, error, type}) {
    const [currentlyVisible, setCurrentlyVisible] = useState(false);

    function handleVisibilityChange() {
        setCurrentlyVisible(!currentlyVisible);
    }

    var inputField = <input className={'form-field-regular__input' + (error ? ' form-field-regular__input--error' : '')} value={value} placeholder={placeholder} onChange={handleChange} />;
    if (type === 'password') {
        var icon = <EyeOpened className='form-field-regular__icon' />
        if (currentlyVisible) {
            icon = <EyeClosed className='form-field-regular__icon' />
        }

        inputField = 
        <div className='form-field-regular__input-wrapper'>
            <input className={'form-field-regular__input' + (error ? ' form-field-regular__input--error' : '')} value={value} placeholder={placeholder} onChange={handleChange} type={currentlyVisible ? '' : 'password'} style={{paddingRight: '35px'}} />
            <div className='form-field-regular__password-button' onClick={handleVisibilityChange}>
                {icon}
            </div>
        </div>;
    }

    var errorLabel = null;
    if (error) {
        errorLabel = <label className='form-field-regular__error'>{error}</label>;
    }

    return (
        <div className='form-field-regular'>
            <label className='form-field-regular__label'>{placeholder}</label>
            {inputField}
            {errorLabel}
        </div>
    );
}