import StarIcon from '../svg/StarIcon';
import QuoteIcon from '../svg/QuoteIcon';

export default function Review({name, rating, pictureUrl, content}) {
    var stars = [];
    for (let i = 0; i < 5; i++) {
        const highlighted = (i + 1 <= rating);
        stars.push(
            <StarIcon key={i} className={'review__star' + (highlighted ? ' review__star--active' : '')} />
        );
    }

    var picture = null;
    if (pictureUrl) {
        picture = <img className='review__user-picture' src={pictureUrl} alt='Zdjęcie profilowe użytkownika' />;
    }

    return (
        <div className='review'>
            <div className='review__cloud'>
                <p className='review__content'>
                    {content}
                </p>
                <div className='review__quote-box'>
                    <QuoteIcon className='review__quote' />
                </div>
                <div className='review__quote-box review__quote-box--top'>
                    <QuoteIcon className='review__quote' />
                </div>
                <div className='review__stars-box'>
                    {stars}
                </div>
            </div>

            <div className='review__user'>
                <div className='review__user-picture-box'>
                    {picture}
                </div>
                <div className='review__user-details'>
                    <label className='review__user-name'>{name}</label>
                    <label className='review__user-note'>Opinia wystawiona na <u>Google Maps</u></label>
                </div>
            </div>
        </div>
    );
}