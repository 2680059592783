// React:
import {Helmet} from 'react-helmet';

import SlidingButton from "../components/SlidingButton";

export default function Error404() {
    return (
        <div className='error-page'>
            <Helmet>
                <title>404 | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <label className='error-page__title'>Szukana podstrona nie istnieje!</label>
            <label className='error-page__code'>Kod błędu: 404</label>
            <SlidingButton text='Strona główna' url='/' />
        </div>
    );
}