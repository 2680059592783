// React:
import {Helmet} from 'react-helmet';

// Components:
import LoginBox from '../components/LoginBox';

export default function AdminLogin() {

    return (
        <div className='admin-login-page'>
            <Helmet>
                <title>Panel Administratora</title>
            </Helmet>

            <LoginBox />
        </div>
    );
}