// React:
import {Helmet} from 'react-helmet';

import Document from '../components/Document';

export default function RTGInfoPage() {
    const content = `
    <b>Informacja o wpływie działalności wykonywanej przez jednostkę organizacyjną polegającej na uruchomieniu pracowni rtg oraz uruchomieniu i stosowaniu aparatów rtg na zdrowie ludzi i na środowisko:</b><br/><br/>

    Na podstawie art. 32c ust. 2 ustawy Prawo atomowe (Dz. U. z 2019 r., poz. 1792, z późn. zm.), informuję, że w jednostce organizacyjnej:<br/><br/>

    <b><center>
    Specj. Przychodnia Lekarsko-Stomatologiczna M. M. Wrzoł sp.j.,<br/>
    Poradnia Chirurgii Stomatologicznej<br/>
    ul. Jastrzębska 3b, 44-253 Rybnik<br/>
    </center></b><br/><br/>

    wykonywana jest działalność związana z narażeniem na promieniowanie jonizujące, polegająca na:<br/><br/>
    
    • uruchamianiu i stosowaniu urządzeń wytwarzających promieniowanie jonizujące, typ aparatu rtg - <b>aparat stomatologiczny punktowy, aparat pantomograficzny</b><br/><br/>
    
    • uruchamianiu pracowni, w których mają być stosowane źródła promieniowania jonizującego, typ pracowni – <b>pracownia rentgenowska stomatologiczna</b><br/><br/>

    Dla jednostki zostały wydane decyzje zezwalające na uruchomienie i stosowanie aparatów rtg oraz na uruchomienie pracowni rtg:<br/><br/>
    
    • MyRay Hyperion MRT - aparat pantomograficzny - decyzja nr: 220/2020 oraz .221/2020 wydana przez ŚPWIS w Katowicach dnia 17.07.2020<br/><br/>
    
    • MyRay RxDc Extend - aparat punktowy stomatologiczny - decyzja nr .218/2020 oraz 219/2020 wydana przez ŚPWIS w Katowicach dnia 17.07.2020<br/><br/>

    Dla jednostki została wydana zgoda na na prowadzenie działalności związanej z narażeniem na promieniowanie jonizujące w celach medycznych polegającej na udzielaniu świadczeń zdrowotnych z zakresu rentgenodiagnostyki – Decyzja nr 25 z dnia 01.04.2022r. wydana przez Państwowego Wojewódzkiego Inspektora Sanitarnego w Katowicach.<br/><br/>
    
    Na podstawie art. 17 ust. 1 ustawy Prawo atomowe, w celu dostosowania sposobu oceny zagrożenia do jego spodziewanego poziomu, pracownicy jednostki organizacyjnej zostali zaliczeni do kategorii B narażenia. Ocena narażenia pracowników prowadzona jest na podstawie: systematycznych pomiarów dawek dozymetrycznych w środowisku pracy w sposób pozwalający stwierdzić prawidłowość zaliczenia pracowników do tej kategorii.<br/><br/>
    
    W przeciągu ostatnich 12 miesięcy nie stwierdzono przekroczenia dawek granicznych dla pracowników.<br/><br/>
    
    W dniu 31.03.2014 dla aparatu punktowego MyRay RxDC oraz 29.06.2020 dla aparatu pantomograficznego MyRay MRT r. przeprowadzono pomiary dozymetryczne rozkładu mocy dawki promieniowania jonizującego X wokół aparatów rtg podczas których potwierdzono, że konstrukcja ścian, stropów, okien, drzwi oraz zainstalowane urządzenia ochronne w pracowni rtg zabezpiecza osoby pracujące, osoby z ogółu ludności przebywające w sąsiedztwie, a także osoby z ogółu ludności w przypadku pracowni rtg znajdującej się w budynku mieszkalnym przed otrzymaniem w ciągu roku dawek określonych w § 2 i § 3 ust. 1 rozporządzenia Ministra Zdrowia z dnia 21 sierpnia 2006 r. (Dz. U. Nr 180, poz. 1325).<br/><br/>
    
    Kierownik jednostki organizacyjnej zapewnia wykonywanie działalności związanej z narażeniem zgodnie z zasadą optymalizacji wymagającą, żeby – przy rozsądnym uwzględnieniu czynników ekonomicznych i społecznych oraz aktualnego stanu wiedzy technicznej – liczba narażonych pracowników i osób z ogółu ludności oraz prawdopodobieństwo ich narażenia były jak najmniejsze, a otrzymywane przez nich dawki promieniowania jonizującego były możliwie małe.<br/><br/>
    
    Na podstawie powyższych informacji stwierdza się, że działalność w minionych 12 miesiącach nie miała negatywnego wpływu na zdrowie ludzi i środowisko.<br/><br/>
    
    W związku z wykonywaniem wyżej opisanej działalności do środowiska są/nie są* uwalniane substancje promieniotwórcze.<br/><br/>
    `

    return (
        <>
            <Helmet>
                <title>Wpływ RTG na zdrowie ludzi i środowisko | Specjalistyczne Centrum Medyczne</title>
            </Helmet>
            <Document title={'Wpływ RTG na zdrowie ludzi i środowisko'} content={content} />
        </>
    );
}