export default function LoginIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M3965 5101 c-27 -13 -328 -307 -958 -938 l-919 -920 -74 18 c-198 47
                -492 51 -689 9 -433 -92 -804 -340 -1041 -695 -202 -304 -297 -640 -281 -995
                50 -1095 1131 -1836 2170 -1489 258 86 465 217 658 415 236 243 378 519 446
                869 34 175 20 511 -27 674 -9 34 -8 36 73 120 45 47 89 100 96 118 11 27 10
                66 -7 250 -11 120 -19 220 -17 222 2 2 88 -4 190 -14 204 -19 246 -17 283 17
                51 47 53 67 33 284 -10 110 -17 201 -15 203 2 2 91 -4 199 -14 107 -9 206 -14
                219 -11 39 10 85 55 96 94 8 28 6 87 -8 230 l-18 192 31 0 c16 0 112 -7 213
                -16 100 -9 198 -13 217 -10 26 5 58 32 142 117 59 60 116 126 125 147 16 33
                18 82 18 473 0 508 -1 514 -80 591 -80 77 -85 78 -594 78 -410 0 -444 -2 -481
                -19z m957 -179 l23 -23 3 -427 3 -427 -84 -83 -83 -82 -228 21 c-125 11 -237
                18 -250 15 -33 -8 -74 -43 -92 -79 -15 -28 -15 -47 2 -234 9 -112 15 -205 13
                -206 -2 -2 -89 4 -194 14 -104 10 -203 16 -218 13 -43 -9 -95 -60 -103 -100
                -3 -19 2 -120 11 -225 10 -104 16 -192 14 -193 -2 -2 -93 5 -203 15 -218 20
                -237 18 -285 -35 -34 -36 -36 -91 -12 -331 l19 -200 -93 -95 c-52 -53 -96
                -107 -100 -122 -4 -16 3 -66 19 -127 52 -208 58 -434 16 -642 -63 -312 -207
                -570 -438 -791 -496 -472 -1257 -534 -1836 -151 -128 85 -308 263 -395 391
                -111 165 -189 349 -233 556 -28 134 -31 396 -5 536 24 130 88 316 147 428 143
                272 375 499 650 637 316 158 682 197 1029 109 52 -13 104 -21 116 -18 12 3
                444 428 961 945 l939 939 432 -2 432 -3 23 -23z"/>
                <path d="M3956 4499 c-55 -43 -18 -139 54 -139 37 0 90 47 90 78 0 11 -12 34
                -26 51 -31 38 -78 41 -118 10z"/>
                <path d="M3068 3632 c-668 -668 -688 -688 -688 -725 0 -49 35 -87 82 -87 30 0
                89 56 720 688 668 668 688 688 688 725 0 49 -35 87 -82 87 -30 0 -89 -56 -720
                -688z"/>
                <path d="M1052 1685 c-40 -14 -85 -33 -100 -42 -106 -66 -188 -170 -222 -282
                -25 -79 -27 -215 -4 -292 62 -210 263 -359 484 -359 137 0 250 48 351 149 195
                196 199 491 9 695 -133 141 -336 193 -518 131z m272 -166 c73 -27 148 -96 183
                -167 24 -49 28 -69 28 -142 -1 -73 -5 -93 -30 -143 -33 -68 -97 -130 -163
                -160 -71 -33 -198 -31 -272 4 -67 31 -130 93 -161 157 -34 68 -34 216 -1 284
                40 82 123 152 212 179 50 15 148 9 204 -12z"/>
            </g>
        </svg>
    );
}