import {useState} from 'react';

export default function useLocalStorage(key, defaultValue) {
    const [variable, setVariable] = useState(() => {
        const value = window.localStorage.getItem(key);

        try {
            if (value) {
                return JSON.parse(value);
            }

            else {
                window.localStorage.setItem(key, JSON.stringify(defaultValue));
                return defaultValue;
            }
        }

        catch (err) {
            return defaultValue;
        }
    });

    function setValue(value) {
        window.localStorage.setItem(key, JSON.stringify(value));
        setVariable(value);
    }

    return [variable, setValue];
}