import AdminPriceTable from "../components/AdminPriceTable";
import AdminTextEditor from "../components/AdminTextEditor";


export default function AdminPricingPage({id, name}) {
    return (
        <div className='admin-base-page'>
            <label className='admin-base-page__title'>{name}</label>
            <AdminPriceTable clinic={id} />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='Dodatkowe uwagi pod cennikiem' url='/api/misc' resourceId={`${id}_notes`} />
        </div>
    );
}