// React:
import {useState, useEffect} from 'react';
import axios from 'axios';

// Components:
import RegularButton from './RegularButton';

export default function AdminTextEditor({title, url, resourceId}) {
    const [resource, setResource] = useState('');
    const [notification, setNotification] = useState({code: 0, msg: ''});

    useEffect(() => {
        setNotification({code: 0, msg: 'Trwa pobieranie zasobów...'});

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}${url}`, {params: {id: resourceId}})
        .then(({data}) => {
            setResource(data.content);
            setNotification({code: 0, msg: ''});
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                // Location not found:
                if (error.response.status === 404) {
                    setNotification({code: -1, msg: 'Nie znaleziono żądanego zasobu!'});
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, [url, resourceId]);

    function handleResourceSave(e) {
        e.preventDefault();

        const data = {
            id: resourceId,
            content: resource
        };

        const options = {
            withCredentials: true
        };

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}${url}`, data, options)
        .then(({data}) => {
            setNotification({code: 1, msg: 'Zmiany zostały zapisane pomyślnie.'});
            setTimeout(() => setNotification({code: 0, msg: ''}), 5000);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                // Wrong credentials:
                if (error.response.status === 401) {
                    setNotification({code: -1, msg: 'Nie jesteś zalogowany lub twoja sesja wygasła. Zaloguj się ponownie.'})
                }

                // Location not found:
                else if (error.response.status === 404) {
                    setNotification({code: -1, msg: 'Nie znaleziono żądanego zasobu!'});
                }

                // The request was well-formed but was unable to be followed due to semantic errors:
                else if (error.response.status === 422) {

                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    return (
        <div className='admin-base-page__editor'>
            <label className='admin-base-page__title'>{title}</label>
            <form className='admin-base-page__form'>
                <textarea className='admin-base-page__textarea' value={resource} onChange={(e) => setResource(e.target.value)} spellCheck={false} />
                <RegularButton text='Zapisz zmiany' onClick={handleResourceSave} />
            </form>
            <label className={'admin-base-page__status' + (notification.code === -1 ? ' admin-base-page__status--error' : (notification.code === 1 ? ' admin-base-page__status--success' : ''))}>{notification.msg}</label>
        </div>
    );
}