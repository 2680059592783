// React:
import {createContext} from 'react';
import { Outlet } from "react-router-dom";

// Auth:
import useAuth from '../authentication/useAuth';


export const AuthContext = createContext();

export default function AdminUsersContainer() {
    const auth = useAuth();
    
    return (
        <main className='main'>
            <AuthContext.Provider value={auth}>
                <Outlet />
            </AuthContext.Provider>
        </main>
    );
}