// Components:
import AdminTextEditor from '../components/AdminTextEditor';


export default function AdminDashboardPage() {
    return (
        <div className='admin-base-page'>
            <AdminTextEditor title='Poradnia Chirurgii Stomatologicznej' url='/api/clinic' resourceId='dental_surgery_clinic' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='Poradnia Stomatologiczna' url='/api/clinic' resourceId='dental_clinic' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='Poradnia Otorynolaryngologiczna' url='/api/clinic' resourceId='otorhinolaryngology_clinic' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='Pracownia Rentgenowska' url='/api/clinic' resourceId='xray_clinic' />
        </div>
    );
}