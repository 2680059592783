// React:
import {Helmet} from 'react-helmet';

import InfoBox from '../components/InfoBox';
import SlidingButton from '../components/SlidingButton';
import CaptionedImage from '../components/CaptionedImage';
import VideoYoutube from '../components/VideoYoutube';
import Image1 from '../img/implantology1.png';
import Image2 from '../img/implantology2.png';
import Image3 from '../img/implantology3.png';
import Broszura from '../documents/broszura.pdf';

export default function ImplantologyPage() {
    const clinicName = 'Implantologia';
    const clinicCaption = ``;
    const infoTitle = 'Implanty zębowe';
    const infoContent = `<b>Implanty zębowe</b> to najlepsza dostępna metoda odtwarzania braków zębowych.<br/><br/>
    W naszej praktyce stosujemy najwyższej jakości niemieckie implanty klasy premium - <b>CONELOG by Camlog</b>. Ponadto dzięki naszym specjalistom - chirurgii stomatologicznej możemy zaoferować pacjentom znacznie więcej aniżeli tylko wszczepienie implantu. Oferujemy całą gamę bardziej złożonych i specjalistycznych zabiegów rekonstrukcyjnych w obrębie kości szczęki i żuchwy umożliwiających pacjentom przygotowanie chirurgiczne do leczenia implantologicznego. W tym celu stosujemy przeszczepy kości czy błony zaporowe ale z naciskiem na jak największe wykorzystanie najlepszych - bo własnych tkanek pacjenta: w tym kości autogennej oraz czynników wzrostu - fibryny bogatopłytkowej (PRF) pozyskiwanej z krwi pacjenta. Celem zminimalizowania ryzyka błędu nie tylko starannie planujemy zabiegi na podstawie odpowiednich badań radiologicznych ale także stosujemy najnowsze technologie w tym m.in. nawigację implantologiczną dzięki wykorzystaniu szablonów drukowanych w technologii 3D na podstawie tomografii komputerowej pacjenta.`;

    const caption1 = 'Niemiecka precyzja od 1999 roku potwierdzona milionami implantów wprowadzanych każdego roku na całym świecie.';
    const caption2 = 'Wybitne wyniki w perspektywie długoterminowej dzięki znakomitej powierzchni PROMOTE';
    const caption3 = 'Precyzyjne połączenie stożkowe implantu z łącznikiem korony protetycznej, które minimalizuje przeciek bakteryjny';



    return (
        <article className='clinic-page'>
            <Helmet>
                <title>Implantologia | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <label className='clinic-page__title'>{clinicName}</label>
            <label className='clinic-page__caption' dangerouslySetInnerHTML={{ __html: clinicCaption }}></label>

            <div className='clinic-page__section'>
                <InfoBox title={infoTitle} content={infoContent} />
            </div>


            <div className='clinic-page__section clinic-page__section--row clinic-page__section--hexagon'>
                <CaptionedImage src={Image1} title='Implanty marki PREMIUM' caption={caption1} />
                <CaptionedImage src={Image2} title='Gwarancja jakości' caption={caption2} narrow={true} />
                <CaptionedImage src={Image3} title='Połączenie stożkowe (konikalne)' caption={caption3} />
            </div>

            <div className='clinic-page__section'>
                <label className='clinic-page__subtitle'>Materiały multimedialne</label>
                <label className='clinic-page__subtitle-caption'>Zapraszamy do zapoznania się z informacją na temat leczenia implantologicznego w postaci krótkich materiałów filmowych</label>
                <div className='clinic-page__row-group'>
                    <VideoYoutube src='https://www.youtube.com/embed/jHUOeBUiUHQ' />
                    <VideoYoutube src='https://www.youtube.com/embed/ZxvcBRWwFMU' />
                </div>
                <label className='clinic-page__info'>Zachęcamy również do przeczytania broszury dla pacjenta, która dostępna jest poniżej:</label>
                <SlidingButton text='Otwórz broszurę' url={Broszura} newPage={true} />
            </div>

            <div className='clinic-page__section'>
                <label className='clinic-page__subtitle'>Cennik świadczeń komercyjnych</label>
                <SlidingButton text='Przejdź do cennika' url='/cennik/poradnia-chirurgii-stomatologicznej' />
            </div>
        </article>
    );
}