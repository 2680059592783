import {useEffect} from 'react';
import logo from '../img/logo.png';
import ArrowDown from '../svg/ArrowDownIcon';

export default function Navbar() {
    useEffect(() => {        
        const navbar = document.getElementById('js-navbar');
        const urgentNotification = document.getElementById('js-urgent-notification');
        var fixed = false;

        const onScroll = () => {
            if (window.innerWidth > 1280 && !fixed && window.scrollY >= urgentNotification.offsetHeight) {
                navbar.setAttribute('class', 'navbar navbar--fixed');
                urgentNotification.setAttribute('class', 'urgent-notification urgent-notification--extended-margin');
                fixed = true;
            }

            else if (window.innerWidth > 1280 && fixed && window.scrollY < urgentNotification.offsetHeight) {
                navbar.setAttribute('class', 'navbar');
                urgentNotification.setAttribute('class', 'urgent-notification');
                fixed = false;
            }
        }

        onScroll();

        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    return (
        <nav className='navbar' id='js-navbar'>
            <a className='navbar__logo-group' href='/'>
                <img className='navbar__logo' src={logo} alt='Logo' />
                <label className='navbar__title'>Specjalistyczne Centrum Medyczne</label>
            </a>
            <div className='navbar__link-group'>
                <div className='navbar__link'>
                    <label className='navbar__link-label'>Poradnie</label>
                    <ArrowDown className='navbar__link-arrow-down' />
                    <div className='navbar__dropdown-arrow'></div>
                    <div className='navbar__dropdown-wrapper'>
                        <div className='navbar__dropdown'>
                            <a className='navbar__dropdown-link' href='/poradnie/poradnia-chirurgii-stomatologicznej'>
                                <label className='navbar__dropdown-link-label'>Poradnia Chirurgii Stomatologicznej</label>
                            </a>
                            <a className='navbar__dropdown-link' href='/poradnie/poradnia-stomatologiczna'>
                                <label className='navbar__dropdown-link-label'>Poradnia Stomatologiczna</label>
                            </a>
                            <a className='navbar__dropdown-link' href='/poradnie/poradnia-otorynolaryngologiczna'>
                                <label className='navbar__dropdown-link-label'>Poradnia Otorynolaryngologii</label>
                            </a>
                            <a className='navbar__dropdown-link' href='/poradnie/pracownia-rentgenowska'>
                                <label className='navbar__dropdown-link-label'>Pracownia Rentgenowska</label>
                            </a>
                        </div>
                    </div>
                </div>

                <a className='navbar__link' href='/implantologia'>
                    <label className='navbar__link-label'>Implantologia</label>
                </a>
                <a className='navbar__link' href='/cennik/poradnia-chirurgii-stomatologicznej'>
                    <label className='navbar__link-label'>Cennik</label>
                </a>
                <a className='navbar__link' href='/#kontakt'>
                    <label className='navbar__link-label'>Kontakt</label>
                </a>
                <a className='navbar__link' href='/#zespół'>
                    <label className='navbar__link-label'>Zespół</label>
                </a>
            </div>
        </nav>
    );
}