// React:
import {useState, useRef, useEffect} from 'react';
import axios from 'axios';

// Icons:
import HeaderIcon from '../svg/HeaderIcon';
import DeleteIcon from "../svg/DeleteIcon";
import AddIcon from "../svg/AddIcon";
import ReorderIcon from '../svg/ReorderIcon';

// Framer Motion:
import {Reorder, useDragControls} from 'framer-motion';

// Components:
import RegularButton from './RegularButton';


function AdminPriceTableRow({id, rows, setRows, service, price, category, isCategory}) {
    const [headerMode, setHeaderMode] = useState(isCategory);
    const controls = useDragControls();

    function handleHeaderModeClick() {
        var newRows = [...rows];
        var row = newRows.find(item => item._id === id);
        row.isCategory = !headerMode;
        setRows(newRows);

        setHeaderMode(!headerMode);
    }

    function handleDeleteClick() {
        var newRows = rows.filter(item => item._id !== id);
        setRows(newRows);
    }

    function handleServiceChange(event) {
        var newRows = [...rows];
        var row = newRows.find(item => item._id === id);
        row.service = event.target.value;
        setRows(newRows);
    }

    function handlePriceChange(event) {
        var newRows = [...rows];
        var row = newRows.find(item => item._id === id);
        row.price = event.target.value;
        setRows(newRows);
    }

    function handleCategoryChange(event) {
        var newRows = [...rows];
        var row = newRows.find(item => item._id === id);
        row.category = event.target.value;
        setRows(newRows);
    }

    if (!headerMode) {
        return (
            <Reorder.Item className='admin-price-table__row-wrapper' key={id} value={rows.find(item => item._id === id)} dragListener={false} dragControls={controls}>
                <div className='admin-price-table__button admin-price-table__button--narrow' onPointerDown={(e) => controls.start(e)} style={{ touchAction: "none" }}>
                    <ReorderIcon className='admin-price-table__icon admin-price-table__icon--full' />
                </div>
                <div className='admin-price-table__row'>
                    <input className='admin-price-table__input' placeholder='Usługa' value={service || ''} onChange={handleServiceChange} />
                    <input className='admin-price-table__input admin-price-table__input--right' placeholder='Cena' value={price || ''} onChange={handlePriceChange} />
                </div>
                <div className='admin-price-table__button-group'>
                    <div className='admin-price-table__button' onClick={handleHeaderModeClick}>
                        <HeaderIcon className='admin-price-table__icon' />
                    </div>
                    <div className='admin-price-table__button admin-price-table__button--delete' onClick={handleDeleteClick}>
                        <DeleteIcon className='admin-price-table__icon' />
                    </div>
                </div>
            </Reorder.Item>
        );
    }
    
    else {
        return (
            <Reorder.Item className='admin-price-table__row-wrapper' key={id} value={rows.find(item => item._id === id)} dragListener={false} dragControls={controls}>
                <div className='admin-price-table__button admin-price-table__button--narrow' onPointerDown={(e) => controls.start(e)} style={{ touchAction: "none" }}>
                    <ReorderIcon className='admin-price-table__icon admin-price-table__icon--full' />
                </div>
                <div className='admin-price-table__row'>
                    <input className='admin-price-table__input admin-price-table__input--category' placeholder='Nazwa kategorii' value={category || ''} onChange={handleCategoryChange} />
                </div>
                <div className='admin-price-table__button-group'>
                    <div className='admin-price-table__button admin-price-table__button--active' onClick={handleHeaderModeClick}>
                        <HeaderIcon className='admin-price-table__icon' />
                    </div>
                    <div className='admin-price-table__button admin-price-table__button--delete' onClick={handleDeleteClick}>
                        <DeleteIcon className='admin-price-table__icon' />
                    </div>
                </div>
            </Reorder.Item>
        );
    }
}

export default function AdminPriceTable({clinic}) {
    const [notification, setNotification] = useState({code: 0, msg: ''});

    const nextId = useRef('NEW 0');
    const incrementNextId = () => {
        var newNumber = parseInt(nextId.current.split(' ')[1]) + 1;
        nextId.current = `NEW ${newNumber}`;
    }

    const [rows, setRows] = useState([]);

    useEffect(() => {
        setNotification({code: 0, msg: 'Trwa pobieranie zasobów...'});

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/service`, {params: {clinic: clinic}})
        .then(({data}) => {
            setRows(data);
            setNotification({code: 0, msg: ''});
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                // Location not found:
                if (error.response.status === 404) {
                    setNotification({code: -1, msg: 'Nie znaleziono żądanego zasobu!'});
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, [clinic]);

    function savePriceTable() {
        setNotification({code: 0, msg: 'Trwa zapisywanie zmian...'});

        var newRows = [...rows];

        for (let i in newRows) {
            newRows[i].order = i;
        }

        const data = {
            rows: newRows,
            clinic: clinic
        };

        const options = {
            withCredentials: true
        };

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/service`, data, options)
        .then(({data}) => {
            
            setNotification({code: 1, msg: 'Zmiany zostały zapisane pomyślnie!'});
            setTimeout(() => setNotification({code: 0, msg: ''}), 5000);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                // Wrong credentials:
                if (error.response.status === 401) {
                    setNotification({code: -1, msg: 'Nie jesteś zalogowany lub twoja sesja wygasła. Zaloguj się ponownie.'})
                }

                // Location not found:
                else if (error.response.status === 404) {
                    setNotification({code: -1, msg: 'Nie znaleziono żądanego zasobu!'});
                }

                // The request was well-formed but was unable to be followed due to semantic errors:
                else if (error.response.status === 422) {

                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }

    var rowComponents = [];
    for (let row of rows) {
        rowComponents.push(
            <AdminPriceTableRow key={row._id} id={row._id} rows={rows} setRows={setRows} service={row.service} price={row.price} category={row.category} isCategory={row.isCategory} />
        );
    }

    function handleRowAddition() {
        setRows([...rows, {_id: nextId.current, isCategory: false, service: '', price: ''}]);
        incrementNextId();
    }

    return (
        <div className='admin-price-table'>
            <div className='admin-price-table__row-wrapper'>
                <div className='admin-price-table__header'>
                    <label className='admin-price-table__label admin-price-table__label--header'>Usługa</label>
                    <label className='admin-price-table__label admin-price-table__label--header'>Cena</label>
                </div>
            </div>
            
            <Reorder.Group className='admin-price-table__reorder-group' axis='y' values={rows} onReorder={setRows}>
                {rowComponents}
            </Reorder.Group>

            <div className='admin-price-table__row-wrapper'>
                <div className='admin-price-table__add-row' onClick={handleRowAddition}>
                    <AddIcon className='admin-price-table__icon admin-price-table__icon--normal' />
                </div>
            </div>
            
            <RegularButton text='Zapisz zmiany' onClick={savePriceTable} />
            <label className={'admin-price-table__notification' + (notification.code === -1 ? ' admin-price-table__notification--error' : (notification.code === 1 ? ' admin-price-table__notification--success' : ''))}>{notification.msg}</label>
        </div>
    );
}