export default function PriceTable({services=[]}) {
    var rows = [];
    services.forEach((item, i) => {
        rows.push(
            <tr key={i} className={'price-table__row' + (i % 2 !== 0 ? ' price-table__row--accent' : '')}>
                <td className={'price-table__cell' + (item.isCategory ? ' price-table__cell--category' : '')} colSpan={item.isCategory ? 2 : 1}>{item.category || item.service}</td>
                <td className='price-table__cell price-table__cell--price' style={(item.isCategory ? {display: 'none'} : {})}>{item.price}</td>
            </tr>
        );
    });

    return (
        <table className='price-table'>
            <thead>
                <tr className='price-table__header'>
                    <th className='price-table__cell'>Usługa</th>
                    <th className='price-table__cell'>Cena</th>
                </tr>
            </thead>
            
            <tbody>
                {rows}
            </tbody>
        </table>
    );
}