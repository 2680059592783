export default function EarIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M3836 5109 c-151 -22 -314 -86 -448 -176 -87 -58 -209 -178 -263
                -259 -21 -32 -42 -63 -46 -67 -4 -5 -46 5 -95 21 -49 16 -123 35 -164 42 -104
                18 -277 16 -385 -4 -94 -18 -616 -206 -752 -272 -110 -53 -217 -129 -308 -220
                -216 -216 -335 -501 -335 -804 0 -92 125 -1233 161 -1460 43 -283 171 -580
                350 -820 84 -112 252 -279 363 -361 117 -87 963 -610 1051 -650 127 -57 203
                -72 370 -72 178 0 258 17 400 87 243 120 406 316 487 586 25 82 35 290 19 383
                -6 35 -81 316 -166 623 -99 355 -159 590 -165 644 -5 47 -10 166 -10 266 0
                203 -4 214 -72 214 -65 0 -73 -19 -76 -204 -6 -287 -1 -317 172 -938 85 -304
                159 -577 165 -606 6 -29 11 -97 11 -151 0 -401 -315 -734 -718 -758 -114 -6
                -224 11 -328 53 -38 15 -166 87 -284 159 -764 470 -792 489 -942 638 -235 234
                -405 552 -468 877 -10 52 -53 390 -95 750 -69 584 -76 669 -72 780 10 253 95
                452 271 637 90 95 173 157 281 210 88 44 618 240 700 259 84 19 274 22 360 5
                77 -16 183 -50 190 -62 3 -5 -5 -45 -19 -89 -14 -45 -29 -105 -32 -135 -4 -30
                -11 -55 -15 -55 -5 0 -53 18 -106 39 -54 22 -121 45 -148 51 -64 15 -225 15
                -290 0 -74 -17 -580 -207 -655 -246 -178 -93 -310 -252 -372 -449 -41 -129
                -37 -219 47 -929 42 -357 82 -684 90 -725 37 -189 141 -424 260 -582 119 -159
                375 -379 441 -379 30 0 74 41 74 69 0 37 -12 51 -91 107 -275 194 -467 482
                -533 801 -22 106 -166 1350 -166 1436 0 187 99 373 256 479 55 37 137 73 362
                158 428 162 434 162 788 23 41 -16 42 -17 48 -77 6 -54 28 -163 41 -206 4 -13
                -18 -15 -158 -15 -184 -1 -278 -16 -419 -69 -211 -79 -377 -226 -482 -426 -84
                -162 -101 -261 -47 -289 58 -32 97 -1 130 104 79 249 277 430 550 503 85 22
                117 25 271 26 96 1 187 -2 201 -6 18 -5 40 -29 66 -71 22 -34 65 -93 96 -130
                l57 -66 0 -105 c0 -125 -21 -204 -79 -292 -46 -70 -62 -85 -302 -287 -215
                -180 -260 -228 -289 -304 -39 -104 -37 -147 14 -367 29 -125 56 -217 73 -248
                35 -64 110 -133 181 -166 56 -27 60 -27 332 -32 l274 -5 70 -245 c64 -227 70
                -252 70 -345 1 -95 -1 -103 -33 -163 -41 -76 -104 -135 -179 -170 -49 -23 -70
                -27 -147 -26 -63 0 -102 5 -130 17 -21 10 -159 92 -306 182 -282 175 -308 186
                -349 145 -23 -23 -26 -68 -7 -93 20 -26 557 -352 622 -377 50 -20 79 -24 170
                -24 95 0 119 4 178 27 86 33 141 72 205 141 59 66 106 154 122 233 25 120 12
                193 -124 678 -69 248 -133 467 -143 488 -22 50 -81 110 -135 138 -32 17 -65
                24 -134 27 -83 4 -94 2 -113 -17 -26 -26 -27 -71 -3 -101 15 -19 32 -24 93
                -29 80 -7 117 -24 145 -66 12 -19 84 -243 84 -262 0 -9 -448 -1 -481 9 -49 14
                -111 67 -132 111 -27 55 -90 338 -91 402 -1 98 20 125 254 323 256 216 283
                242 334 322 67 104 106 217 106 307 0 24 2 43 5 43 3 0 39 -18 79 -40 177 -98
                339 -135 551 -127 167 6 267 29 401 92 129 60 205 114 310 219 135 136 226
                295 276 484 32 122 36 329 9 460 -52 249 -182 463 -375 618 -88 70 -233 149
                -336 182 -143 47 -327 62 -474 41z m378 -169 c310 -83 547 -314 643 -627 24
                -79 27 -104 27 -243 1 -162 -8 -218 -56 -340 -86 -223 -288 -424 -511 -510
                -122 -48 -222 -63 -371 -57 -208 8 -363 65 -523 192 -307 242 -424 669 -283
                1033 95 245 307 449 551 532 159 55 365 62 523 20z"/>
                <path d="M3780 4769 c-14 -6 -36 -20 -48 -32 -46 -43 -52 -67 -52 -221 l0
                -145 -154 -3 c-168 -3 -182 -8 -230 -72 -19 -25 -21 -44 -24 -219 -4 -214 3
                -244 62 -288 29 -22 43 -24 186 -29 l155 -5 5 -150 c6 -171 14 -197 78 -233
                37 -21 52 -22 227 -22 208 0 237 7 278 64 20 28 22 46 27 186 l5 155 155 5
                c175 6 196 13 231 81 17 34 19 60 19 227 0 180 -1 191 -22 222 -48 70 -63 75
                -234 78 l-154 3 0 148 c0 137 -2 150 -22 181 -50 72 -58 75 -271 77 -114 1
                -202 -2 -217 -8z m362 -325 c4 -241 -14 -224 229 -224 l179 0 0 -154 0 -155
                -181 -3 -181 -3 -24 -28 c-23 -27 -24 -33 -24 -203 l0 -174 -155 0 -155 0 0
                174 c0 170 -1 176 -24 203 l-24 28 -181 3 -181 3 0 155 0 154 180 0 c244 0
                230 -14 230 230 l0 180 154 0 155 0 3 -186z"/>
                <path d="M401 3234 c-41 -34 -141 -168 -186 -249 -130 -232 -170 -555 -101
                -818 45 -174 154 -372 265 -481 58 -57 101 -66 133 -27 32 41 23 74 -40 145
                -80 91 -149 208 -188 323 -41 121 -54 199 -54 328 0 232 78 439 238 630 71 86
                79 120 36 154 -35 28 -66 26 -103 -5z"/>
                <path d="M577 3011 c-287 -307 -289 -804 -4 -1126 38 -42 49 -49 82 -49 27 0
                43 6 56 23 31 38 24 67 -37 139 -116 140 -169 298 -161 482 7 165 60 298 171
                428 53 61 59 97 23 130 -40 37 -78 29 -130 -27z"/>
                <path d="M780 2818 c-50 -54 -104 -155 -124 -234 -19 -72 -21 -180 -5 -256 27
                -129 142 -298 202 -298 35 0 77 41 77 74 0 13 -16 43 -37 68 -20 23 -50 72
                -67 108 -29 60 -31 73 -31 170 0 92 4 112 26 160 14 30 42 75 63 99 47 56 54
                85 29 119 -14 19 -31 28 -56 30 -32 3 -42 -2 -77 -40z"/>
            </g>
        </svg>
    );
}