export default function ArrowDownIcon({className}) {
   return (
      <svg className={className} height="469.99304" width="800" viewBox="0 0 492.004 289.04807">
         <g id="g6" transform="rotate(90,296.741,195.263)">
            <g id="g4">
               <path
            d="M 382.678,226.804 163.73,7.86 C 158.666,2.792 151.906,0 144.698,0 137.49,0 130.73,2.792 125.666,7.86 l -16.124,16.12 c -10.492,10.504 -10.492,27.576 0,38.064 L 293.398,245.9 109.338,429.96 c -5.064,5.068 -7.86,11.824 -7.86,19.028 0,7.212 2.796,13.968 7.86,19.04 l 16.124,16.116 c 5.068,5.068 11.824,7.86 19.032,7.86 7.208,0 13.968,-2.792 19.032,-7.86 L 382.678,265 c 5.076,-5.084 7.864,-11.872 7.848,-19.088 0.016,-7.244 -2.772,-14.028 -7.848,-19.108 z"
            id="path2" />
            </g>
         </g>
      </svg>
   );
}