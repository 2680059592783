// React:
import {useState, useRef} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Navigate} from 'react-router-dom';

// Page containers:
import RegularUsersContainer from './pages/RegularUsersContainer';
import AdminUsersContainer from './pages/AdminUsersContainer';
import AdminBaseContainer from './pages/AdminBaseContainer';

// Pages:
import MainPage from './pages/MainPage';
import DentalSurgeryClinicPage from './pages/DentalSurgeryClinicPage';
import DentalClinicPage from './pages/DentalClinicPage';
import OtolaryngologyClinicPage from './pages/OtolaryngologyClinicPage';
import XRayLabPage from './pages/XRayLabPage';
import ImplantologyPage from './pages/ImplantologyPage';
import PricingPage from './pages/PricingPage';
import DoctorMWPage from './pages/DoctorMWPage';
import DoctorMGWPage from './pages/DoctorMGWPage';
import DoctorKKPage from './pages/DoctorKKPage';
import DoctorMSPage from './pages/DoctorMSPage';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RTGInfoPage from './pages/RTGDocumentPage';
import Error404 from './pages/Error404';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import AdminClinicsPage from './pages/AdminClinicsPage';
import AdminTeamPage from './pages/AdminTeamPage';
import AdminPricingSelectionPage from './pages/AdminPricingSelectionPage';
import AdminPricingPage from './pages/AdminPricingPage';
import AdminMessagesPage from './pages/AdminMessagesPage';
import AdminAccountPage from './pages/AdminAccountPage';

// Auth:
import RequireAuth from './authentication/RequireAuth';

// Redirects:
import PricingRedirect from './redirects/PricingRedirect';

// Stylesheets:
import './css/index.css';


export default function App() {
    const [cookieBannerVisible, setCookieBannerVisible] = useState(false); // Global state of cookie banner visibility
    const [notifications, setNotifications] = useState({}); // Global object with all notifications
    const notificationsRef = useRef();
    notificationsRef.current = notifications; // Reference to notifications state allows to get up-to-date notifications
    const [notificationIndex, setNotificationIndex] = useState(0); // Index of next notification

    function addNotification(type, message) {
        const newNotifications = {
            ...notifications,
            [notificationIndex]: {type: type, message: message}
        };
        setNotifications(newNotifications);
        
        setTimeout(() => {
            deleteNotification(notificationIndex);
        }, 10000)

        
        setNotificationIndex(notificationIndex + 1);
    }

    function deleteNotification(index) {
        var newNotifications = {...notificationsRef.current};
        delete newNotifications[index];
        setNotifications(newNotifications);
    }

    return (
        <main className='main'>

            <Router>
                <Routes>
                    <Route path='/' element={<RegularUsersContainer notifications={notifications} deleteNotification={deleteNotification} cookieBannerVisible={cookieBannerVisible} setCookieBannerVisible={setCookieBannerVisible} />}>
                        <Route path='/' element={<MainPage addNotification={addNotification} />} />

                        <Route path='/poradnie' element={<Navigate to='/#poradnie' />} />
                        <Route path='/poradnie/poradnia-chirurgii-stomatologicznej' element={<DentalSurgeryClinicPage />} />
                        <Route path='/poradnie/poradnia-stomatologiczna' element={<DentalClinicPage />} />
                        <Route path='/poradnie/poradnia-otorynolaryngologiczna' element={<OtolaryngologyClinicPage />} />
                        <Route path='/poradnie/pracownia-rentgenowska' element={<XRayLabPage />} />
                        <Route path='/implantologia' element={<ImplantologyPage />} />

                        <Route path='/cennik' element={<PricingRedirect />} />
                        <Route path='/cennik/:clinic' element={<PricingPage />} />

                        <Route path='/zespół' element={<Navigate to='/#zespół' />} />
                        <Route path='/zespół/maciej-wrzoł' element={<DoctorMWPage />} />
                        <Route path='/zespół/marta-gamrot-wrzoł' element={<DoctorMGWPage />} />
                        <Route path='/zespół/krzysztof-krasiński' element={<DoctorKKPage />} />
                        <Route path='/zespół/magdalena-śpiewok' element={<DoctorMSPage />} />

                        <Route path='/dokumenty/polityka-plików-cookie' element={<CookiePolicy setCookieBannerVisible={setCookieBannerVisible} />} />
                        <Route path='/dokumenty/polityka-prywatności' element={<PrivacyPolicy />} />
                        <Route path='/dokumenty/rtg-wpływ-na-ludzi-i-środowisko' element={<RTGInfoPage />} />


                        <Route path='*' element={<Error404 />} />
                    </Route>
                    
                    <Route path='/admin' element={<AdminUsersContainer />}>
                        <Route path='/admin/login' element={<AdminLoginPage />} />
                        <Route path='/admin' element={<RequireAuth><AdminBaseContainer/></RequireAuth>}>
                            <Route path='/admin/dashboard' element={<AdminDashboardPage />} />
                            <Route path='/admin/pricing' element={<AdminPricingSelectionPage />} />
                            <Route path='/admin/pricing/poradnia-chirurgii-stomatologicznej' element={<AdminPricingPage name='Poradnia Chirurgii Stomatologicznej' id='pcs' />}/>
                            <Route path='/admin/pricing/poradnia-chirurgii-stomatologicznej-nfz' element={<AdminPricingPage name='Poradnia Chirurgii Stomatologicznej - NFZ' id='pcs_nfz' />}/>
                            <Route path='/admin/pricing/poradnia-stomatologiczna' element={<AdminPricingPage name='Poradnia Stomatologiczna' id='ps' />}/>
                            <Route path='/admin/pricing/poradnia-otorynolaryngologiczna' element={<AdminPricingPage name='Poradnia Otorynolaryngologiczna' id='po' />}/>
                            <Route path='/admin/pricing/pracownia-rentgenowska' element={<AdminPricingPage name='Pracownia Rentgenowska' id='pr' />}/>
                            <Route path='/admin/clinics' element={<AdminClinicsPage />} />
                            <Route path='/admin/team' element={<AdminTeamPage />} />
                            <Route path='/admin/messages' element={<AdminMessagesPage />} />
                            <Route path='/admin/account' element={<AdminAccountPage />} />

                            <Route path='/admin/*' element={<Navigate to='/admin/dashboard' />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </main>
    );
}