import {useState, useEffect} from 'react';
import ArrowRightIcon from '../svg/ArrowRightIcon';

export default function ImageCarousel({images=[]}) {
    const n = images.length;

    const [queue, setQueue] = useState([2, ...new Array(n - 2).fill(n).map((item, i) => item - i), 1]);

    useEffect(() => {
        function autoNextImage() {
            var newQueue = [...queue.slice(n - 1, n), ...queue.slice(0, n - 1)];
            setQueue(newQueue);
        }

        var intervalId = setInterval(autoNextImage, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [n, queue]);

    function handleLeftClick() {
        var newQueue = [...queue.slice(1, n), ...queue.slice(0, 1)];
        setQueue(newQueue);
    }

    function handleRightClick() {
        var newQueue = [...queue.slice(n - 1, n), ...queue.slice(0, n - 1)];
        setQueue(newQueue);
    }

    var zIndexes = [...queue];
    var items = [];
    var dots = [];
    for (let i = 0; i < images.length; i++) {
        var zIndex = zIndexes[i];

        var attr = '';
        if (zIndex === 2) {
            attr = ' image-carousel__item--left';
        }

        else if (zIndex === n) {
            attr = ' image-carousel__item--center';
        }

        items.push(
            <div key={i} className={'image-carousel__item' + attr} style={{zIndex: zIndex}}>
                <img className='image-carousel__image' src={images[i]} alt={'Zdjęcie'} />
            </div>
        );

        var activeImage = zIndexes.indexOf(n);

        dots.push(
            <div key={i} className={'image-carousel__dot' + (activeImage === i ? ' image-carousel__dot--active' : '')}></div>
        );
    }

    return (
        <div className='image-carousel'>
            <div className='image-carousel__controls'>
                <div className='image-carousel__arrow-box' onClick={handleLeftClick}>
                    <ArrowRightIcon className='image-carousel__arrow image-carousel__arrow--left' />
                </div>
                <div className='image-carousel__arrow-box' onClick={handleRightClick}>
                    <ArrowRightIcon className='image-carousel__arrow image-carousel__arrow--right' />
                </div>
            </div>

            {items}

            <div className='image-carousel__dots'>
                {dots}
            </div>
        </div>
    );
}