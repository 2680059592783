export default function AdminPricingSelectionPage() {
    return (
        <div className='admin-base-page'>
            <label className='admin-base-page__title'>Wybierz cennik, który chcesz edytować:</label>
            <div className='admin-base-page__selection-container'>
                <a className='admin-base-page__selection-link' href='/admin/pricing/poradnia-chirurgii-stomatologicznej'>Poradnia Chirurgii Stomatologicznej</a>
                <a className='admin-base-page__selection-link' href='/admin/pricing/poradnia-chirurgii-stomatologicznej-nfz'>Poradnia Chirurgii Stomatologicznej - NFZ</a>
                <a className='admin-base-page__selection-link' href='/admin/pricing/poradnia-stomatologiczna'>Poradnia Stomatologiczna</a>
                <a className='admin-base-page__selection-link' href='/admin/pricing/poradnia-otorynolaryngologiczna'>Poradnia Otorynolaryngologiczna</a>
                <a className='admin-base-page__selection-link' href='/admin/pricing/pracownia-rentgenowska'>Pracownia Rentgenowska</a>
            </div>
        </div>
    );
}