export default function SendIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M394 4892 c-89 -31 -144 -89 -170 -181 -11 -36 -14 -70 -10 -105 4
                -28 99 -417 210 -864 216 -863 213 -853 275 -867 14 -3 413 -48 886 -100 473
                -52 872 -97 886 -100 14 -3 39 -20 55 -36 24 -25 29 -39 29 -79 0 -40 -5 -54
                -29 -79 -16 -16 -41 -33 -55 -36 -14 -3 -413 -48 -886 -100 -473 -52 -872 -97
                -886 -100 -62 -14 -59 -4 -275 -867 -111 -447 -206 -836 -210 -864 -21 -173
                136 -329 300 -296 29 6 190 78 376 169 179 87 1119 546 2089 1018 1516 739
                1771 867 1816 907 147 133 147 363 0 496 -45 40 -300 168 -1816 907 -970 472
                -1910 930 -2088 1017 -384 188 -400 193 -497 160z"/>
            </g>
        </svg>
    );
}