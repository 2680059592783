import LoginIcon from '../svg/LoginIcon';

export default function LoginButton({text}) {
    return (
        <button className='login-button' type='submit'>
            <div className='login-button__icon-box'>
                <LoginIcon className='login-button__icon' />
            </div>
            <div className='login-button__icon-box-fill'></div>
            <div className='login-button__label-box'>
                <label className='login-button__label'>{text}</label>
            </div>
        </button>
    );
}