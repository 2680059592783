import ReactGA from 'react-ga4';
import Cookies from 'universal-cookie';

export function enableGoogleAnalytics() {
    ReactGA.initialize('G-M5TXY2ZX88', {
        gtagOptions: {
            cookie_domain: 'specjalistyczna.com.pl',
            cookie_flags: 'SameSite=None;Secure'
        }
    });
}

export function disableGoogleAnalytics() {
    var cookies = new Cookies(null, {path: '/'});
    var regex = /^_ga/;

    for (let name in cookies.getAll()) {
        if (regex.test(name)) {
            cookies.remove(name, {path: '/', domain: '.specjalistyczna.com.pl'});
        }
    }
}