// Components:
import AdminClientMessages from '../components/AdminClientMessages';


export default function AdminMessagesPage() {
    return (
        <div className='admin-base-page'>
            <label className='admin-base-page__title'>Wiadomości z formularza kontaktowego</label>
            <AdminClientMessages />
        </div>        
    );
}