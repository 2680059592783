import {useState, useEffect} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';


export default function AdminNavBar() {
    const [visible, setVisible] = useState(window.screen.width > 1280 ? true : false);
    const [messagesCount, setMessagesCount] = useState(0);
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const options = {
            withCredentials: true
        };

        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/message-count`, options)
        .then(({data}) => {
            setMessagesCount(data.count);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch messages count [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    function handleVisibilityChange() {
        setVisible(!visible);
    }

    return (
        <>
        <label className='admin-nav-bar__show-button' style={{display: visible ? 'none' : 'initial'}} onClick={handleVisibilityChange}>ROZWIŃ MENU</label>
        <div className='admin-nav-bar' style={{display: visible ? 'flex' : 'none'}}>
            <div className='admin-nav-bar__items-container'>
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/dashboard" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/dashboard' className='admin-nav-bar__link'>Ustawienia ogólne</a>
                </div>
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/messages" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/messages' className='admin-nav-bar__link'>Wiadomości (<span className={'admin-nav-bar__number' + (messagesCount ? '' : ' admin-nav-bar__number--zero')}>{messagesCount}</span>)</a>
                </div>
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/pricing" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/pricing' className='admin-nav-bar__link'>Cennik</a>
                </div>
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/clinics" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/clinics' className='admin-nav-bar__link'>Poradnie</a>
                </div>
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/team" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/team' className='admin-nav-bar__link'>Zespół</a>
                </div>
                
                <div className='admin-nav-bar__item'>
                    <div className={'admin-nav-bar__dot' + (path === "/admin/account" ? ' admin-nav-bar__dot--active' : '')}></div>
                    <a href='/admin/account' className='admin-nav-bar__link'>Konto</a>
                </div>
            </div>
            <label className='admin-nav-bar__hide-button' onClick={handleVisibilityChange}>ZWIŃ</label>
        </div>
        </>
    );
}