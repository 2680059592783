// React:
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import InfoBox from '../components/InfoBox';
import MediumImage from '../components/MediumImage';
import SlidingButton from '../components/SlidingButton';
import Picture from '../img/pr.jpg';

export default function XRayLabPage() {
    const [infoContent, setInfoContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/clinic`, {params: {id: 'xray_clinic'}})
        .then(({data}) => {
            setInfoContent(data.content);
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                console.error(`API: Unable to fetch clinic content [${error.response.status}]`);
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
            }
        });
    }, []);

    const clinicName = 'Pracownia Rentgenowska';
    const clinicCaption = ``;
    const infoTitle = 'Informacje ogólne';
    const infoTitle2 = 'Informacje o wpływie na zdrowie ludzi i środowisko';
    const infoContent2 = `Z informacją o wpływie działalności wykonywanej przez jednostkę organizacyjną polegającej na uruchomieniu pracowni rtg oraz uruchomieniu i stosowaniu aparatów rtg na zdrowie ludzi i na środowisko można zapoznać się klikając na poniższy przycisk:`;

    return (
        <article className='clinic-page'>
            <Helmet>
                <title>Pracownia Rentgenowska | Specjalistyczne Centrum Medyczne</title>
            </Helmet>

            <label className='clinic-page__title'>{clinicName}</label>
            <label className='clinic-page__caption' dangerouslySetInnerHTML={{__html:clinicCaption}}></label>
            
            <div className='clinic-page__section'>
                <MediumImage src={Picture} alt='Pracownia rentgenowska' />
                <InfoBox title={infoTitle} content={infoContent} additionalStyle={{top: '-70px'}} />
            </div>
            
            <div className='clinic-page__section'>
                <label className='clinic-page__subtitle'>Cennik świadczeń komercyjnych</label>
                <SlidingButton text='Przejdź do cennika' url='/cennik/pracownia-rentgenowska' />
            </div>
            
            <div className='clinic-page__section'>
                <InfoBox title={infoTitle2} content={infoContent2} buttonText='Dowiedz się więcej' buttonUrl='/dokumenty/rtg-wpływ-na-ludzi-i-środowisko' />
            </div>
        </article>
    );
}