import SendIcon from '../svg/SendIcon';

export default function SendButton({text}) {
    return (
        <button className='send-button' type='submit'>
            <div className='send-button__icon-box'>
                <SendIcon className='send-button__icon' />
            </div>
            <div className='send-button__icon-box-fill'></div>
            <div className='send-button__label-box'>
                <label className='send-button__label'>{text}</label>
            </div>
        </button>
    );
}