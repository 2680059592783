// React:
import { Outlet } from "react-router-dom";

// Components:
import UrgentNotification from "../components/UrgentNotification";
import Navbar from "../components/Navbar";
import NavbarMobile from "../components/NavbarMobile";
import NotificationBox from "../components/NotificationBox";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";

export default function RegularUsersContainer({notifications, deleteNotification, cookieBannerVisible, setCookieBannerVisible}) {
    return (
        <main className='main'>
            <UrgentNotification />
            <Navbar />
            <NavbarMobile />

            <Outlet />

            <NotificationBox notifications={notifications} deleteNotification={deleteNotification} />
            <CookieBanner visible={cookieBannerVisible} setVisible={setCookieBannerVisible} />
            <Footer setCookieBannerVisible={setCookieBannerVisible} />
        </main>
    );
}