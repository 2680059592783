// Components:
import AdminTextEditor from '../components/AdminTextEditor';


export default function AdminDashboardPage() {
    return (
        <div className='admin-base-page'>
            <AdminTextEditor title='Treść powiadomienia na górze strony' url='/api/misc' resourceId='top_notification' />
        </div>
    );
}