// Components:
import AdminTextEditor from '../components/AdminTextEditor';


export default function AdminTeamPage() {
    return (
        <div className='admin-base-page'>
            <AdminTextEditor title='dr n. med. Maciej Wrzoł' url='/api/team' resourceId='mw' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='dr n. med. Marta Gamrot-Wrzoł' url='/api/team' resourceId='mgw' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='lek. dent. Krzysztof Krasiński' url='/api/team' resourceId='kk' />
            <hr className='admin-base-page__separator' />
            <AdminTextEditor title='lek. dent. Magdalena Śpiewok' url='/api/team' resourceId='ms' />
        </div>
    );
}