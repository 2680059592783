// React
import {useState, useEffect} from 'react';
import Cookies from 'universal-cookie';

// Components
import RegularButton from './RegularButton';
import Checkbox from './Checkbox';

// Functions
import { enableGoogleAnalytics, disableGoogleAnalytics } from '../functions/GoogleAnalytics';


export default function CookieBanner({visible, setVisible}) {
    const [expanded, setExpanded] = useState(false);
    const cookies = new Cookies(null, {path: '/'});

    const consentCookieName = 'cookie_consent';
    const consentCookieAge = 30 * 24 * 60 * 60; // One month

    // Hide CookieBanner when consent has been already specified
    // (Only on initial render)
    useEffect(() => {
        var consent = cookies.get(consentCookieName);
        if (consent) {
            consent = Object.fromEntries(consent.split(',').map((item) => item.split(':')));
            
            if (consent.analytics === 'yes') {
                enableGoogleAnalytics();
            }
            else {
                disableGoogleAnalytics();
            }
        }

        else {
            disableGoogleAnalytics();
            setVisible(true);
        }
    }, []); // eslint-disable-line

    function onAcceptAll() {
        cookies.set(consentCookieName, 'analytics:yes', {maxAge: consentCookieAge});
        enableGoogleAnalytics();
        setVisible(false);
    }

    function onDenyAll() {
        cookies.set(consentCookieName, 'analytics:no', {maxAge: consentCookieAge});
        disableGoogleAnalytics();
        setVisible(false);
    }

    function onCustom() {
        var analytics = document.getElementsByClassName('js-cookie-analytics')[0];
        
        if (analytics.checked) {
            cookies.set(consentCookieName, 'analytics:yes', {maxAge: consentCookieAge});
            enableGoogleAnalytics();
        }

        else {
            cookies.set(consentCookieName, 'analytics:no', {maxAge: consentCookieAge});
            disableGoogleAnalytics();
        }

        setVisible(false);
    }

    var info = <p className='cookie-banner__info'>
        Klikając "Zaakceptuj wszystkie", wyrażasz zgodę na przechowywanie plików cookie 
        na Twoim urządzeniu w celach funkcjonalnych i analitycznych. 
        Więcej informacji znajdziesz w <a className='cookie-banner__link' href='/dokumenty/polityka-plików-cookie'>Polityce plików cookie</a>.
        </p>;

    var buttons = <div className='cookie-banner__button-layout'>
        <RegularButton text='Zaakceptuj wszystkie' onClick={onAcceptAll} />
        <RegularButton text='Odrzuć wszystkie' onClick={onDenyAll} transparent={true} />
        <RegularButton text='Więcej opcji' onClick={() => setExpanded(true)} transparent={true} />
    </div>;

    var checkboxes = null;

    if (expanded) {
        info = null;

        buttons = <div className='cookie-banner__button-layout'>
            <RegularButton text='Potwierdź swój wybór' onClick={onCustom} />
            <RegularButton text='Polityka plików cookie' onClick={() => window.location.href = '/dokumenty/polityka-plików-cookie'} transparent={true} />
            <RegularButton text='Mniej opcji' onClick={() => setExpanded(false)} transparent={true} />
        </div>;

        checkboxes = <div className='cookie-banner__options'>
            <div className='cookie-banner__option'>
                <div className='cookie-banner__option-title-layout'>
                    <Checkbox additionalClass='js-cookie-necessary' inactive={true} />
                    <label className='cookie-banner__option-title'>Niezbędne</label>
                </div>
                <p className='cookie-banner__option-info'>
                    Zapewniają prawidłowe funkcjonowanie naszej strony i jej podstawowych funkcji. 
                    Bez nich nie będziesz mógł prawidłowo korzystać z naszych usług online.
                </p>
            </div>
            <hr className='cookie-banner__options-separator' />
            <div className='cookie-banner__option'>
                <div className='cookie-banner__option-title-layout'>
                    <Checkbox additionalClass='js-cookie-analytics' />
                    <label className='cookie-banner__option-title'>Analityczne</label>
                </div>
                <p className='cookie-banner__option-info'>
                    Analityczne pliki cookie umożliwiają śledzenie liczby i źródeł odwiedzin, 
                    dzięki czemu możemy mierzyć i poprawiać wydajność naszej strony internetowej.
                </p>
            </div>
        </div>
    }

    if (visible) {
        return (
            <div className='cookie-banner__wrapper'>
                <div className='cookie-banner'>
                    <label className={'cookie-banner__title' + (expanded ? ' cookie-banner__title--expanded' : '')}>
                        {(!expanded) ? 'Informacja o plikach cookie' : 
                        'Wybierz kategorie plików cookie, na które wyrażasz zgodę'}
                    </label>
                    
                    {info}
                    {checkboxes}
                    {buttons}
                </div>
            </div>
        );
    }

    else {
        return (null);
    }
}