import InfoIcon from '../svg/InfoIcon';
import SlidingButton from './SlidingButton';

export default function InfoBox({title, content, additionalStyle, buttonText, buttonUrl}) {
    var button;
    if (buttonText && buttonUrl) {
        button = <SlidingButton text={buttonText} url={buttonUrl} />;
    }

    return (
        <div className='info-box' style={additionalStyle}>
            <div className='info-box__title-box'>
                <InfoIcon className='info-box__icon' />
                <label className='info-box__title'>{title}</label>                
            </div>
            <div className='info-box__content-box'>
                <p className='info-box__content' dangerouslySetInnerHTML={{__html:content}}></p>
                {button}
            </div>
        </div>
    );
}