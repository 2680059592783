import useLocalStorage from '../hooks/useLocalStorage';

export default function useAuth() {
    const localStorageName = 'auth';
    const [status, setStatus] = useLocalStorage(localStorageName, false);

    function confirm() {
        setStatus(true);
    }

    function deny() {
        setStatus(false);
    }

    return {
        status,
        confirm,
        deny
    }
}