import {useState} from 'react';
import axios from 'axios';

import PinIcon from "../svg/PinIcon";
import PhoneIcon from "../svg/PhoneIcon";
import EmailIcon from "../svg/EmailIcon";
import FormField from "./FormField";
import Checkbox from './Checkbox';
import SendButton from "./SendButton";

import * as GAEvents from '../functions/GoogleAnalyticsEvents';

export default function ContactForm({addNotification}) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState(false);

    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [privacyPolicyError, setPrivacyPolicyError] = useState('');

    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            name: name,
            phone: phone,
            email: email,
            message: message,
            privacyPolicy: privacyPolicy
        };

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/forms/validate-contact`, data)
        .then(({data}) => {
            setNameError('');
            setPhoneError('');
            setEmailError('');
            setMessageError('');
            setPrivacyPolicyError('');

            setName('');
            setPhone('');
            setEmail('');
            setMessage('');

            setPrivacyPolicy(false);
            document.getElementsByClassName('js-contact-form__privacy-checkbox')[0].checked = false;
            
            addNotification('success', 'Otrzymaliśmy Twoją wiadomość! Wkrótce na nią odpowiemy :)');
            GAEvents.contactFormSuccessEvent();
        })
        .catch((error) => {
            // When server responded with some error code
            if (error.response) {
                if (error.response.status === 422) {
                    const data = error.response.data;

                    setNameError('');
                    setPhoneError('');
                    setEmailError('');
                    setMessageError('');
                    setPrivacyPolicyError('');

                    if (data.name) {
                        setNameError(data.name.msg);
                        GAEvents.contactFormNameErrorEvent();
                    }
    
                    if (data.phone) {
                        setPhoneError(data.phone.msg);
                        GAEvents.contactFormPhoneErrorEvent();
                    }
                    
                    if (data.email) {
                        setEmailError(data.email.msg);
                        GAEvents.contactFormEmailErrorEvent(email);
                    }
    
                    if (data.message) {
                        setMessageError(data.message.msg);
                        GAEvents.contactFormMessageErrorEvent();
                    }
    
                    if (data.privacyPolicy) {
                        setPrivacyPolicyError(data.privacyPolicy.msg);
                        GAEvents.contactFormPrivacyPolicyErrorEvent();
                    }
    
                    addNotification('error', 'W formularzu kontaktowym pojawiły się błędy. Musisz je poprawić, aby pomyślnie wysłać formularz!');
                    GAEvents.contactFormGeneralFailureEvent(); // Register Google Analytics event
                }

                else if (error.response.status === 429) {
                    addNotification('error', 'Wysłałeś zbyt wiele zapytań. Spróbuj ponownie za godzinę.');
                }

                else {
                    addNotification('error', 'Błąd po stronie serwera! Spróbuj ponownie później.');
                    console.error(`API [${error.response.status}]:`, error.response.data);
                }
            }

            // When request was made but server didn't respond
            else if (error.request) {
                console.error('API: Server didn\'t respond!')
                addNotification('error', 'Serwer nie odpowiada! Spróbuj ponownie później.');
            }

            // Request wasn't sent successfully (configuration error)
            else {
                console.error('API:', error.message);
                addNotification('error', 'Błąd konfiguracji zapytania! Spróbuj ponownie później.');
            }
        });
    }

    return (
        <div className='contact-form'>
            <div className='contact-form__details'>
                <label className='contact-form__title'>Skontaktuj się z nami!</label>
                <div className='contact-form__detail-boxes'>
                    <div className='contact-form__detail-box'>
                        <div className='contact-form__detail-icon-box'>
                            <PinIcon className='contact-form__detail-icon' />
                        </div>
                        <div className='contact-form__detail-label-box'>
                            <label className='contact-form__detail-label contact-form__detail-label--title'>Lokalizacja:</label>
                            <label className='contact-form__detail-label'>ul. Patriotów 11 (parter)<br/>44-253 Rybnik<br/>Polska</label>
                        </div>
                    </div>
                    <div className='contact-form__detail-box'>
                        <div className='contact-form__detail-icon-box'>
                            <PhoneIcon className='contact-form__detail-icon' />
                        </div>
                        <div className='contact-form__detail-label-box'>
                            <label className='contact-form__detail-label contact-form__detail-label--title'>Telefon:</label>
                            <a className='contact-form__detail-label' href='tel:32 440 88 77'>32 440 88 77</a>
                        </div>
                    </div>
                    <div className='contact-form__detail-box'>
                        <div className='contact-form__detail-icon-box'>
                            <EmailIcon className='contact-form__detail-icon' />
                        </div>
                        <div className='contact-form__detail-label-box'>
                            <label className='contact-form__detail-label contact-form__detail-label--title'>Email:</label>
                            <a className='contact-form__detail-label' href='mailto:rejestracja@specjalistyczna.com.pl'>rejestracja@specjalistyczna.com.pl</a>
                        </div>
                    </div>
                </div>
            </div>

            <form className='contact-form__form' onSubmit={handleSubmit}>
                <div className='contact-form__form-title-box'>
                    <label className='contact-form__title contact-form__title--left'>Formularz kontaktowy</label>
                    <p className='contact-form__subtitle'>Jeśli chcesz się zarejestrować lub masz jakieś pytania/wątpliwości, możesz wypełnić poniższy formularz. Odpowiemy najszybciej jak to możliwe!</p>
                </div>

                <div className='contact-form__fields-row'>
                    <div className='contact-form__field-wrapper'>
                        <FormField value={name} placeholder='Imię i nazwisko' handleChange={(e) => setName(e.target.value)} error={Boolean(nameError)} />
                        <label className='contact-form__error-label'>{nameError}</label>
                    </div>
                    <div className='contact-form__field-wrapper'>
                        <FormField value={phone} placeholder='Telefon (opcjonalnie)' handleChange={(e) => setPhone(e.target.value)} error={Boolean(phoneError)} />
                        <label className='contact-form__error-label'>{phoneError}</label>
                    </div>
                </div>

                <div className='contact-form__field-wrapper'>
                    <FormField value={email} placeholder='Adres email' handleChange={(e) => setEmail(e.target.value)} error={Boolean(emailError)} />
                    <label className='contact-form__error-label'>{emailError}</label>
                </div>

                <div className='contact-form__field-wrapper contact-form__field-wrapper--expand'>
                    <FormField value={message} placeholder='Twoja wiadomość' handleChange={(e) => setMessage(e.target.value)} type='textarea' error={Boolean(messageError)} styling={{height: '100%'}} />
                    <label className='contact-form__error-label'>{messageError}</label>
                </div>

                <div className='contact-form__field-wrapper'>
                    <div className='contact-form__checkbox-wrapper'>
                        <Checkbox additionalClass='js-contact-form__privacy-checkbox' onClick={(event) => setPrivacyPolicy(event.target.checked)} />
                        <label className='contact-form__checkbox-label'>Oświadczam, że zapoznałem/am się z <a href='/dokumenty/polityka-prywatności' className='contact-form__checkbox-link' target='_blank'>Polityką prywatności</a>.</label>
                    </div>
                    
                    <label className='contact-form__error-label'>{privacyPolicyError}</label>
                </div>

                <SendButton text='Wyślij' />
            </form>
        </div>
    );
}