export default function HealingToothIcon({className}) {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M966 4499 c-148 -22 -313 -85 -437 -169 -86 -58 -209 -178 -278 -270
                -58 -77 -136 -218 -152 -272 -12 -44 11 -94 55 -114 31 -15 39 -16 73 -3 33
                13 44 26 81 98 113 221 250 362 442 456 112 56 205 77 345 78 193 1 364 -53
                583 -185 85 -51 111 -73 139 -115 75 -113 219 -218 353 -257 91 -27 206 -31
                296 -11 99 22 176 56 198 86 36 52 9 131 -52 149 -32 10 -46 8 -105 -15 -111
                -41 -223 -43 -327 -3 -24 9 -21 11 45 42 39 18 107 58 152 88 391 263 763 294
                1078 91 268 -174 455 -520 455 -843 l0 -77 -118 -6 c-360 -20 -653 -152 -893
                -404 -460 -484 -478 -1238 -42 -1747 l51 -60 -38 -76 c-43 -85 -81 -124 -138
                -140 -46 -12 -58 -12 -105 1 -51 14 -103 61 -121 112 -9 23 -68 290 -132 592
                -71 339 -124 567 -137 595 -66 134 -262 148 -350 24 -24 -34 -43 -113 -152
                -627 -69 -324 -129 -596 -135 -606 -18 -34 -79 -80 -121 -91 -67 -18 -140 2
                -186 52 -12 12 -65 115 -118 228 -318 673 -523 1076 -641 1259 -87 134 -197
                350 -238 466 -31 88 -70 135 -112 135 -56 0 -103 -45 -104 -99 0 -78 146 -392
                278 -598 127 -198 281 -499 602 -1178 144 -303 151 -316 219 -378 187 -168
                496 -103 601 126 21 46 59 207 145 617 65 305 121 562 126 570 7 13 8 13 13 0
                3 -8 59 -270 125 -582 128 -605 135 -629 209 -705 189 -195 503 -145 632 99
                18 35 34 64 35 66 1 1 26 -14 56 -34 79 -54 262 -140 349 -164 240 -66 479
                -66 720 0 106 29 300 127 400 201 451 334 637 916 465 1449 -62 190 -155 346
                -294 493 -177 186 -345 284 -638 373 -9 2 -13 31 -13 98 0 195 -37 351 -129
                541 -154 320 -410 538 -726 621 -83 22 -123 27 -235 28 -75 0 -161 -5 -195
                -12 -188 -40 -374 -121 -561 -244 -111 -72 -148 -88 -209 -88 -61 0 -97 15
                -215 92 -305 201 -607 286 -874 247z m2985 -1459 c183 -21 381 -100 527 -209
                469 -354 580 -1015 251 -1502 -54 -82 -176 -207 -259 -269 -286 -211 -661
                -267 -996 -149 -160 56 -267 123 -396 247 -230 221 -349 518 -335 832 14 295
                120 536 326 740 238 236 555 347 882 310z"/>
                <path d="M3616 2630 c-56 -28 -76 -48 -102 -105 -20 -41 -24 -66 -24 -142 l0
                -93 -92 0 c-104 0 -152 -16 -201 -66 -58 -58 -62 -75 -65 -264 -3 -175 -3
                -175 25 -230 18 -36 43 -65 71 -85 40 -28 52 -30 150 -35 l107 -5 6 -105 c6
                -113 17 -146 66 -191 63 -59 83 -64 273 -64 160 0 178 2 215 22 22 11 50 32
                61 45 50 55 58 78 64 187 l5 106 107 5 c98 5 110 7 150 35 23 17 54 51 68 75
                23 42 25 56 28 206 4 187 -4 228 -56 286 -55 61 -99 78 -209 78 l-93 0 0 93
                c0 108 -26 178 -81 219 -56 42 -92 48 -268 48 -144 -1 -171 -3 -205 -20z m354
                -332 c5 -145 5 -148 33 -175 27 -28 30 -28 175 -33 l147 -5 0 -135 0 -135
                -140 -5 c-209 -7 -215 -14 -215 -225 0 -112 -2 -134 -16 -139 -20 -8 -228 -8
                -248 0 -14 5 -16 27 -16 139 0 212 -6 218 -217 223 l-144 4 3 136 3 137 148 5
                149 5 29 33 29 32 0 138 c0 76 3 142 7 146 4 3 66 5 137 4 l131 -3 5 -147z"/>
                <path d="M47 3399 c-22 -13 -47 -61 -47 -93 0 -14 13 -40 29 -58 24 -27 36
                -33 71 -33 35 0 47 6 71 33 16 18 29 44 29 58 0 33 -25 81 -49 94 -25 13 -81
                12 -104 -1z"/>
            </g>
        </svg>
    );
}