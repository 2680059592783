import ReactGA from 'react-ga4';

export function contactFormSuccessEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_Success',
        label: 'User filled and sent Contact Form successfully'
    });
}

export function contactFormGeneralFailureEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_UserError',
        label: 'User error in contact form'
    });
}

export function contactFormNameErrorEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_IncorrectName',
        label: 'User error in "name" field'
    });
}

export function contactFormPhoneErrorEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_IncorrectPhoneNumber',
        label: 'User error in "phone" field'
    });
}

export function contactFormEmailErrorEvent(email) {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_IncorrectEmailAddress',
        label: 'User error in "email" field',
        empty: email ? false : true,
        email: email
    });
}

export function contactFormMessageErrorEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_IncorrectMessage',
        label: 'User error in "message" field'
    });
}

export function contactFormPrivacyPolicyErrorEvent() {
    ReactGA.event({
        category: 'ContactForm',
        action: 'ContactForm_IncorrectPrivacyPolicy',
        label: 'User error in "privacy_policy" field'
    });
}